<template>
    <v-app>
        <v-main touchless>
            <section class="pt-0">
                <v-container class="pb-0">
                    <v-row>
                        <v-col class="col-lg-12 pa-0 ma-0 pb-3">
                            <TopNavBar />
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <section class="pt-0 insights-section">
                <div class="subpage-header-section border-top">
                    <h1 class="single-title">MacguyverTech: In The Media</h1>
                </div>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <div class="body-text pt-0">
                                <template>
                                    <v-row class="mt-2 mb-2">
                                        <v-col class="col-lg-4 col-md-4 col-sm-12 col-xs-12" v-for="(item, index) in posts" v-bind:key="index">
                                            <v-card class="mx-auto" max-width="450" height="430px" color="#ffffff">
                                                <a :href="item.link">
                                                    <v-img :src="item.img" height="250px" width="auto">
                                                    </v-img>
                                                </a>
                                                <div>
                                                    <v-card-text class="mt-0 pt-1">
                                                        <div class="news-card-text">
                                                            <h3 class="mt-0 insights-title" style="line-height: 1.2em;margin-bottom:.5em ;"><a target="_blank" :href="item.link" style="color:#0189E4;">{{ item.title}}</a></h3>
                                                                <div class="text-center">
                                                                    <v-chip color="#1E1E1E" class="catchip text-center" :href="item.link" outlined>{{ item.pubpub }}: {{ item.date }}</v-chip>
                                                                </div>
                                                            
                                                        </div>
                                                    </v-card-text>
                                                </div>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </template>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <FooterBar />
        </v-main>
    </v-app>
</template>
<script>
import TopNavBar from './components/TopNavBar.vue'
import FooterBar from './components/FooterBar.vue'
import axios from 'axios'
export default {
    name: 'AppMedia',
    components: { "TopNavBar": TopNavBar, "FooterBar": FooterBar },
    data: () => ({
        loading: false,
        selection: 1,
        posts: [],
        cats: [],
    }),
    created() {},
    methods: {},
    mounted() {

        axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/media_data/1')
        .then(res => {
            //console.log('data', res.data);
            this.posts = res.data.map(x => x);

        }, error => {
            console.log(error);
        });     

    }
}
</script>
<style scoped lang="scss">
.blog {
    .section-body-text-blog {
        color: #000000;
        padding: 52px 42px;
    }

    .header-color {
        color: #ee7d40;
    }

    .featured-border {
        padding-left: 33px;
        position: relative;
    }

    .featured-border span.subheader {
        font-weight: 700;
        font-size: 50px;
        line-height: 57px;
    }

    .featured-border .border-left {
        border-left: 5px solid #ee7d40;
        position: absolute;
        top: 4%;
        bottom: 0;
        left: 0;
    }

    .featured-border .border-top {
        border-top: 5px solid #ee7d40;
        position: absolute;
        top: 4%;
        left: 0;
        right: 98.5%;
    }

    .featured-border .border-bottom {
        border-bottom: 5px solid #ee7d40;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 60%;
    }

    .featured_mediatext {
        font-size: 25px;
        line-height: 29px;
        color: #0085FF;
        font-weight: 700;
    }

    // .featured_media {
    //     background: linear-gradient(360deg, #ee7d40 17.34%, rgba(217, 217, 217, 0) 87.83%);
    // }
    // .featured_media .v-image.v-responsive.featured_media.theme--dark .v-responsive__sizer {
    //     background: linear-gradient(360deg, #ee7d40 17.34%, rgba(217, 217, 217, 0) 87.83%)
    // }

    .recent-border {
        padding-left: 33px;
        position: relative;
    }

    .recent-border span.subheader {
        font-weight: 700;
        font-size: 50px;
        line-height: 57px;
    }

    .recent-border .border-left {
        border-left: 5px solid #ee7d40;
        position: absolute;
        top: 8%;
        bottom: 0;
        left: 0;
    }

    .recent-border .border-top {
        border-top: 5px solid #ee7d40;
        position: absolute;
        top: 7.6%;
        left: 0;
        right: 97.4%;
    }

    .recent-border .border-bottom {
        border-bottom: 5px solid #ee7d40;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 47%;
    }

    .recent_mediatext_wrapper {
        margin-top: 0px;
        text-align: left;
    }

    .recent_mediatext {
        font-weight: 400;
        font-size: 25px;
        line-height: 29px;
        color: #0085FF;
    }
}

// tablet styles
@media (max-width: 960px) {
    .blog {
        .recent-border .border-top {
            top: 5.6%;
            left: 0;
            right: 96.4%;
        }

        .recent-border .border-left {
            top: 6%;
            bottom: 0;
            left: 0;
        }

        .featured-border .flex-direction-row-tb {
            flex-direction: row;
        }

        .featured_mediatext_wrapper {
            margin-top: -10px;
            text-align: left;
        }

        .blog-card-text {
            padding-top: 0;
            padding-bottom: 0;
        }

        .blog-card-text .categories {
            color: #EE7D40;
        }

    }
}

@media (max-width: 600px) {
    .blog {
        .featured-border .flex-direction-column-m {
            flex-direction: column;
        }

        .section-body-text-blog {
            padding: 5px 25px;
            padding-left: 10px;
        }

        .section-body-text-blog h2 {
            font-weight: 700;
            font-size: 40px;
            line-height: 46px;
            margin-bottom: 6px;
        }

        .featured-border {
            padding-left: 25px;
            position: relative;
        }

        .featured-border span.subheader {
            font-weight: 700;
            font-size: 25px;
            line-height: 29px;
            padding-left: 10px;
        }

        .featured-border .border-left {
            border-left: 1px solid #ee7d40;
            position: absolute;
            top: 1.2%;
            bottom: 0;
            left: 0;
        }

        .featured-border .border-top {
            border-top: 1px solid #ee7d40;
            position: absolute;
            top: 1.2%;
            left: 0;
            right: 93.5%;
        }

        .featured-border .border-bottom {
            border-bottom: 1px solid #ee7d40;
            position: absolute;
            left: 0;
            bottom: 0;
            right: 82%;
        }

        .featured_mediatext_wrapper {
            margin-top: -10px;
            text-align: left;
        }

        .blog-card-text {
            padding-top: 0;
            padding-bottom: 0;
        }

        .blog-card-text .categories {
            color: #EE7D40;
        }

        .v-image.v-responsive.featured_media.theme--dark {
            border-width: 8px;
        }

        .recent-border {
            text-align: right;
            padding-left: 0px;
            padding-right: 15px;
        }

        .recent-border span.subheader {
            font-weight: 700;
            font-size: 25px;
            line-height: 29px;
            margin-right: 35px;
        }

        .recent_mediatext_wrapper {
            text-align: right;
        }

        .recent_mediatext {
            font-weight: 400;
            font-size: 20px;
            line-height: 23px;
        }

        .recent-border .border-left {
            border-right: 1px solid #ee7d40;
            border-left: 0;
            right: 0;
            top: 3%;
            bottom: 0;
            left: 100%;
        }

        .recent-border .border-top {
            border-top: 1px solid #ee7d40;
            top: 3%;
            right: 0;
            left: 91%;
        }

        .recent-border .border-bottom {
            border-bottom: 1px solid #ee7d40;
            right: 0;
            bottom: 0;
            left: 64%;
        }

    }
}
</style>
<style type="text/css">
</style>