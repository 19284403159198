import Vue from 'vue'
/* eslint-disable*/
import VueYouTubeEmbed from 'vue-youtube-embed'
import Home from './AppHome.vue'
import PageSingle from './AppPageSingle.vue'
import PageSingleLearnMore from './AppPageSingleLearnMore.vue'
import PageOurServices from './AppPageOurServices.vue'
import Careers from './AppCareers.vue'
import Blog from './AppBlog.vue'
import OurProcess from './AppOurProcess.vue'
import OurWork from './AppOurWork.vue'
import About from './AppAbout.vue'
import BlogSingle from './AppBlogSingle.vue'
import BlogCategory from './AppBlogCategory.vue'
import App404 from './App404.vue'
import Contactus from './AppContactus.vue'
import Demos from './AppContactusDemos.vue'
import SingleScheduleCall from './AppPageSingleScheduleCall.vue'
import AppPageSingleLegacy from './AppPageSingleLegacy.vue'
import HTA from './AppPageSingleHTA.vue'
import Media from './AppMedia.vue'
import AI from './AppPageSingleAI.vue'

/* eslint-enable */
import vuetify from './plugins/vuetify'
Vue.config.productionTip = false
import VueNavigationBar from './components/vue-navigation-bar/vue-navigation-bar';
import './components/vue-navigation-bar/dist/vue-navigation-bar.css';
Vue.component("vue-navigation-bar", VueNavigationBar);
import VueLuxon from "vue-luxon";
Vue.use(VueLuxon, {
    input: {
        zone: "America/New_York",
        format: "yyyy-MM-dd HH:mm"
    },
    output: {
        zone: "local",
        format: {
            year: "numeric",
            month: "long",
            day: "numeric"
        },
    },
    icons: {
        iconfont: 'mdi' || 'fa' || 'far'
    },
});

let pageTemplate = document.querySelector('meta[name="pagetemplate"]').content;
let template = 'PageSingle';

if (pageTemplate.length > 0 && document.getElementById("app")) {

    if (pageTemplate =='PageSingle')
        template = PageSingle;
    else if (pageTemplate =='PageSingleLearnMore')
        template = PageSingleLearnMore;
    else if (pageTemplate =='SingleScheduleCall')
        template =  SingleScheduleCall;
    else if (pageTemplate =='AppPageSingleLegacy')
        template =  AppPageSingleLegacy;
    else if (pageTemplate =='PageOurServices')
        template = PageOurServices;
    else if (pageTemplate =='Home')
        template = Home;
    else if (pageTemplate =='Careers')
        template = Careers;
    else if (pageTemplate =='Blog')
        template = Blog;
    else if (pageTemplate =='OurProcess')
        template = OurProcess;
    else if (pageTemplate =='OurWork')
        template = OurWork;
    else if (pageTemplate =='About'){
        Vue.use(VueYouTubeEmbed)
        template = About;
    }       
    else if (pageTemplate =='BlogSingle')
            template = BlogSingle;
    else if (pageTemplate =='Contactus')
            template = Contactus;
    else if (pageTemplate =='404')
            template = App404;
    else if (pageTemplate =='Demos')
            template = Demos;
    else if (pageTemplate =='HTA')
            template = HTA;
    else if (pageTemplate =='Media')
            template = Media;
    else if (pageTemplate =='AI')
        template = AI;         
    //console.log('template', template)
        
    new Vue({
        vuetify,
        render: h => h(template)
    }).$mount('#app')

}

else if ( document.getElementById("app-blog-category") ) {

    template = BlogCategory;

    new Vue({
        vuetify,
        render: h => h(template)
    }).$mount('#app-blog-category')


} else if ( document.getElementById("app-blog-single") ) {

    template = BlogSingle;

    new Vue({
        vuetify,
        render: h => h(template)
    }).$mount('#app-blog-single')

 } else {

    template = App404;

    new Vue({
        vuetify,
        render: h => h(template)
    }).$mount('#app')

}
