<template>
    <v-app>
        <v-main touchless>
            <section class="pt-0">
                <v-container class="pb-0">
                    <v-row>
                        <v-col class="col-lg-12 pa-0 ma-0 pb-3">
                            <TopNavBar />
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <section class="pt-0 insights-section">
                <div class="subpage-header-section border-top">
                    <h1 class="single-title">Blog</h1>
                </div>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <div class="body-text pt-0">
                                <template>
                                    <v-row>
                                        <v-col cols="12">
                                            <div class="text-center">
                                                <h2>Latest</h2>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="col-lg-4 col-md-4 col-sm-12 col-xs-12" v-for="(item, index) in posts" v-bind:key="index">
                                            <v-card class="mx-auto" max-width="450" height="510px" color="#ffffff">
                                                <a :href="item.link" ><v-img :src="item.featured_media_img" height="250px" width="auto">
                                                </v-img></a>
                                                <div>
                                                    <v-card-text>
                                                        <div class="news-card-text">
                                                            <v-chip-group class="justify-end" column>
                                                                <div item.categories v-for="(cat, i) in item.categories_data" v-bind:key="i">
                                                                    <v-chip color="#1E1E1E" :href="cat.category_url" class="catchip" outlined>{{ cat.name }}</v-chip>
                                                                </div>
                                                            </v-chip-group>
                                                            <h3 class="mt-3 insights-title"><a :href="item.link" style="color:#0189E4;">{{ item.title.rendered }}</a></h3>
                                                            <p class="text-center" v-html="item.date"></p>
                                                        </div>
                                                    </v-card-text>
                                                </div>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="showmore">
                                        <v-col cols="12">
                                            <div class="text-center">
                                                <v-progress-circular v-if="showmoreActive" indeterminate color="primary"></v-progress-circular>
                                                <v-btn v-else class="body-button blue" @click="getNewPosts()" title="read more" small>
                                                    <v-icon color="#ffffff" small style="margin-right:.5em;">fas fa-blog</v-icon> Read More Blog
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </template>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <section class="pt-0 pb-4 mt-4" style="background-color: #D9D9D9;">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <div class="text-center">
                                <h2>Categories</h2>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="flex-direction-row-tb flex-direction-column-m">
                        <v-col class="col-lg-12">
                            <template>
                                <v-chip-group class="justify-end" column>
                                    <div item.categories v-for="(cat, i) in cats" v-bind:key="i">
                                        <v-chip color="#1E1E1E" :href="cat.category_url" class="catchipOther" outlined large>{{ cat.name }} ({{ cat.category_count }})</v-chip>
                                    </div>
                                </v-chip-group>
                            </template>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <FooterBar />
        </v-main>
    </v-app>
</template>
<script>
import TopNavBar from './components/TopNavBar.vue'
import FooterBar from './components/FooterBar.vue'
import axios from 'axios'
export default {
    name: 'AppBlog',
    components: { "TopNavBar": TopNavBar, "FooterBar": FooterBar },
    data: () => ({
        loading: false,
        selection: 1,
        posts: [],
        postsFeatured: [],
        cats: [],
        postPage: 1,
        postPageURL: '',
        showmore: false,
        showmoreActive: false,
    }),
    created() {},
    methods: {

        getNewPosts() {

            this.showmoreActive = true;
            this.postPage = this.postPage + 1;
            this.postPageURL = 'https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/posts/' + this.postPage;

            fetch(this.postPageURL)
                .then((r) => r.json())
                .then((res) => {
                    this.posts.push(...res)

                    let p = this.posts[0].postsPages
                    console.log(this.posts[0].postsPages)

                    if (this.postPage >= p) {
                        this.showmore = false
                    } else {
                        this.showmore = true
                    }
                    this.showmoreActive = false;
                })

        }

    },
    mounted() {

        this.showmoreActive = true;

        axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/posts/1')
            .then(res => {
                //console.log(response.data);
                this.inthenews = res.data[0].acf.in_the_media_images;
                this.whatwedo = res.data[0].acf.what_we_do;
                this.ourservice = res.data[0].acf.our_service;
                this.ourprocess = res.data[0].acf.our_process;

                this.posts = res.data
                let p = this.posts[0].postsPages
                console.log(this.posts[0].postsPages)

                if (this.postPage >= p) {
                    this.showmore = false
                } else {
                    this.showmore = true
                }

                this.showmoreActive = false;

            }, error => {
                console.log(error);
            });

        axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/posts_featured/1')
            .then(res => {

                this.postsFeatured = res.data

            }, error => {
                console.log(error);
            });

        axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/categories/1')
            .then(res => {

                this.cats = res.data

            }, error => {
                console.log(error);
            });

    }
}
</script>
<style scoped lang="scss">
.news-card-text {
    height: 150px;
}
</style>