<template>
    <v-app>
        <v-main touchless>
            <section class="pt-0">
                <v-container class="pb-0">
                    <v-row>
                        <v-col class="col-lg-12 pa-0 ma-0 pb-3 ">
                            <TopNavBar />
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <section class="pt-0">
                <div class="subpage-header-section border-top">
                    <h1 class="single-title" v-if="title">{{title}}</h1>
                </div>
                <v-container>
                    <v-row>
                        <v-col cols="12" class="pa-1">
                            <div class="page-body mt-4">
                                <v-row class="pt-6">
                                    <v-col cols="12" md="12" lg="6" xl="6" class="pa-3 pb-0">
                                        <div><iframe style="margin-bottom:10px;" class="youtube-video" src="https://www.youtube.com/embed/DiQHyZxS6Vk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                                    </v-col>
                                    <v-col cols="12" md="12" lg="6" xl="6" class="pa-3 pb-0">
                                        <div>
                                            <p :style="fontSize">
                                                Macguyvertech offers a diverse range of services tailored to enhance business technology infrastructures. Their expertise in Legacy Systems ensures older applications remain efficient and secure, integrating seamlessly into modern tech environments. The Custom Apps service transforms unique business ideas into practical solutions, boosting operational efficiency and competitive edge. They prioritize Ongoing Development for web and mobile applications, embracing innovation and user feedback to stay ahead in the digital marketplace.</p>
                                            <p :style="fontSize">In cybersecurity, their Code Review & Remediation and Penetration Testing services proactively safeguard systems through meticulous analysis and ethical hacking, addressing vulnerabilities and fortifying defenses. Their Strategy and Management services provide fractional CTO/CISO expertise, guiding organizations through technology complexities and aligning initiatives with business goals for effective digital transformation.</p>
                                            <p :style="fontSize">Additionally, their Websites and Integrations focus on creating custom, user-friendly online platforms and efficient workflows, enhancing business presence and operational efficiency. Overall, Macguyvertech is committed to delivering innovative and effective technology solutions, supporting businesses in leveraging technology for improved performance and growth.</p>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <h2><span style="color: #0189e4;">Software Applications & AI</span></h2>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12" lg="4" xl="4" class="pa-0 pr-3 pl-3 pb-3">
                                        <div class="pa-0 pt-2">
                                            <v-card :min-height="boxHeight" color="#ffffff" class="flexcard" height="100%">
                                                <v-lazy>
                                                    <a href="https://macguyvertech.com/our-services/legacy-systems/">
                                                        <v-img alt="WEB AND MOBILE APPLICATIONS: LEGACY SYSTEMS" src="https://macguyvertech.com/wp-content/uploads/2023/12/mtoffice2.jpg" height="250px" width="auto">
                                                        </v-img>
                                                    </a>
                                                </v-lazy>
                                                <div>
                                                    <v-card-text>
                                                        <div class="news-card-text" :style="boxHeightText">
                                                            <h3 class="mt-3 text-center" style="font-size: 2em;"><a href="https://macguyvertech.com/our-services/legacy-systems/" style="color:#0189E4;line-height: 1.2;">Legacy Systems</a></h3>
                                                            <p class="mt-3" :style="fontSize">In the realm of Legacy Systems, our focus is on the meticulous maintenance and robust security of older applications. These systems, often critical to business operations, require specialized attention to ensure they continue to function efficiently and securely in a modern technological landscape. Our approach involves updating and refining these systems to meet current standards, thereby extending their lifespan and enhancing their performance. This not only preserves the investment made in these systems but also ensures they remain a reliable and secure part of your technology infrastructure.</p>
                                                            <div class="text-center pt-4" :style="learnBox">
                                                                <v-btn class="body-button blue" href="https://macguyvertech.com/our-services/legacy-systems/" title="Legacy Systems">
                                                                    <v-icon color="#ffffff" small style="margin-right:.5em;">mdi mdi-information</v-icon> Learn More
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </v-card-text>
                                                </div>
                                            </v-card>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" md="12" lg="4" xl="4" class="pa-0 pr-3 pl-3 pb-3">
                                        <div class="pa-0 pt-2">
                                            <v-card :min-height="boxHeight" color="#ffffff" class="flexcard" height="100%">
                                                <v-lazy>
                                                    <a href="https://macguyvertech.com/our-services/ai-consulting-development/">
                                                        <v-img alt="MacguyverTech AI" src="https://macguyvertech.com/wp-content/uploads/2024/10/macguyvertechai.jpg" height="250px" width="auto">
                                                        </v-img>
                                                    </a>
                                                </v-lazy>
                                                <div>
                                                    <v-card-text>
                                                        <div class="news-card-text" :style="boxHeightText">
                                                            <h3 class="mt-3 text-center" style="font-size: 2em;"><a href="https://macguyvertech.com/our-services/ai-consulting-development/" style="color:#0189E4;line-height: 1.2;">MacguyverTech AI</a></h3>
                                                            <p class="mt-3" :style="fontSize">MacguyverTech AI specializes in developing bespoke, ethical, and secure AI strategies that transform businesses. We excel in legacy systems and automation, advanced machine learning, and AI solutions, utilizing cutting-edge technologies like ChatGPT and LlamaIndex. Our full-stack development expertise includes Python, C#, JavaScript, and more, alongside modern frameworks such as React and VueJS. We offer robust database management and cloud platform services, with a strong focus on cybersecurity, including digital forensics and penetration testing. As a trusted partner in AI consulting and development, we help businesses leverage AI to enhance operations and drive innovation.</p>
                                                            <div class="text-center pt-4" :style="learnBox">
                                                                <v-btn class="body-button blue" href="https://macguyvertech.com/our-services/ai-consulting-development/" title="MacguyverTech AI">
                                                                    <v-icon color="#ffffff" small style="margin-right:.5em;">mdi mdi-information</v-icon> Learn More
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </v-card-text>
                                                </div>
                                            </v-card>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" md="12" lg="4" xl="4" class="pa-0 pr-3 pl-3 pb-3">
                                        <div class="pa-0 pt-2">
                                            <v-card :min-height="boxHeight" color="#ffffff" class="flexcard" height="100%">
                                                <v-lazy>
                                                    <a href="https://macguyvertech.com/our-services/ongoing-development/">
                                                        <v-img alt="WEB AND MOBILE APPLICATIONS: ONGOING DEVELOPMENT" src="https://macguyvertech.com/wp-content/uploads/2023/06/pexels-fauxels-3184357-scaled.jpg" height="250px" width="auto">
                                                        </v-img>
                                                    </a>
                                                </v-lazy>
                                                <div>
                                                    <v-card-text>
                                                        <div class="news-card-text" :style="boxHeightText">
                                                            <h3 class="mt-3 text-center" style="font-size: 2em;"><a href="https://macguyvertech.com/our-services/ongoing-development/" style="color:#0189E4;line-height: 1.2;">Ongoing Development</a></h3>
                                                            <p class="mt-3" :style="fontSize">In the ever-changing realm of digital technology, ongoing development in web and mobile applications is vital for staying relevant and competitive. This continuous process involves regularly updating features, optimizing performance, and integrating user feedback, all while adapting to the latest technological advancements. It’s a strategic blend of innovation, user-centric design, and agile methodologies, ensuring that applications not only meet current user needs and market trends but also anticipate future demands. This approach is essential for businesses to maintain engagement, offer.</p>
                                                            <div class="text-center pt-4" :style="learnBox">
                                                                <v-btn class="body-button blue" href="https://macguyvertech.com/our-services/ongoing-development/" title="Ongoing Development">
                                                                    <v-icon color="#ffffff" small style="margin-right:.5em;">mdi mdi-information</v-icon> Learn More
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </v-card-text>
                                                </div>
                                            </v-card>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-4">
                                        <h2><span style="color: #0189e4;">Cyber Security / CISO / WordPress</span></h2>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12" lg="4" xl="4" class="pa-0 pr-3 pl-3 pb-3">
                                        <div class="pa-0 pt-2">
                                            <v-card :min-height="boxHeight" color="#ffffff" class="flexcard" height="100%" style="position: relative">
                                                <v-lazy>
                                                    <a href="https://macguyvertech.com/our-services/penetration-testing/">
                                                        <v-img alt="CYBER SECURITY: PENETRATION TESTING" src="https://macguyvertech.com/wp-content/uploads/2023/02/penetration-testing.jpg" height="250px" width="auto">
                                                        </v-img>
                                                    </a>
                                                </v-lazy>
                                                <div>
                                                    <v-card-text>
                                                        <div class="news-card-text" :style="boxHeightText">
                                                            <h3 class="mt-3 text-center" style="font-size: 2em;"><a href="https://macguyvertech.com/our-services/penetration-testing/" style="color:#0189E4;line-height: 1.2;">Penetration Testing</a></h3>
                                                            <p class="mt-3" :style="fontSize">Our Penetration Testing service employs advanced ethical hacking techniques to identify and address potential security threats to your systems. By simulating real-world attacks, we uncover vulnerabilities that might be exploited by malicious actors. This proactive approach to security helps in fortifying your defenses, ensuring that your systems are resilient against both current and emerging threats. Our team uses the latest tools and methodologies to provide comprehensive testing, offering you peace of mind and a robust security posture.</p>
                                                            <div class="text-center pt-4" :style="learnBox">
                                                                <v-btn class="body-button blue" href="https://macguyvertech.com/our-services/penetration-testing/" title="Penetration Testing">
                                                                    <v-icon color="#ffffff" small style="margin-right:.5em;">mdi mdi-information</v-icon> Learn More
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </v-card-text>
                                                </div>
                                            </v-card>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" md="12" lg="4" xl="4" class="pa-0 pr-3 pl-3 pb-3">
                                        <div class="pa-0 pt-2">
                                            <v-card :min-height="boxHeight" color="#ffffff" class="flexcard" height="100%" style="position: relative">
                                                <v-lazy>
                                                    <a href="https://macguyvertech.com/our-services/hacker-threat-assessment-hta/">
                                                        <v-img alt="Hacker Threat Assessment (HTA)" src="https://macguyvertech.com/wp-content/uploads/2024/04/hackerBad.png" height="250px" width="auto">
                                                        </v-img>
                                                    </a>
                                                </v-lazy>
                                                <div>
                                                    <v-card-text>
                                                        <div class="news-card-text" :style="boxHeightText">
                                                            <h3 class="mt-3" style="font-size: 2em;"><a href="https://macguyvertech.com/our-services/hacker-threat-assessment-hta/" style="color:#0189E4;line-height: 1.2;">Hacker Threat Assessment (HTA)</a></h3>
                                                            <p class="mt-3" :style="fontSize">In today’s digital landscape, understanding your organization’s security posture from a hacker’s perspective is not just an option—it’s a necessity. Our Hacker Threat Assessment (HTA) offers a comprehensive evaluation, providing a clear view of your company’s digital vulnerabilities just as a hacker would see them. With a focus on your network, website, open ports, and web applications, HTA uncovers potential threats that could be exploited by malicious actors.</p>
                                                            <div class="text-center pt-4" :style="learnBox">
                                                                <v-btn class="body-button blue" href="https://macguyvertech.com/our-services/hacker-threat-assessment-hta/" title="Hacker Threat Assessment (HTA)">
                                                                    <v-icon color="#ffffff" small style="margin-right:.5em;">mdi mdi-information</v-icon> Learn More
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </v-card-text>
                                                </div>
                                            </v-card>
                                        </div>
                                    </v-col>
                                    <!--
                                    <v-col cols="12" md="12" lg="4" xl="4" class="pa-0 pr-3 pl-3 pb-3">
                                        <div class="pa-0 pt-2">
                                            <v-card :min-height="boxHeight" color="#ffffff" class="flexcard" height="100%" style="position: relative">
                                                <v-lazy>
                                                    <a href="https://macguyvertech.com/our-services/fractional-cto-or-ciso-strategy-and-management/">
                                                        <v-img alt="FRACTIONAL CTO OR CISO: STRATEGY AND MANAGEMENT" src="https://macguyvertech.com/wp-content/uploads/2023/07/pexels-christina-morillo-1181376-scaled.jpg" height="250px" width="auto">
                                                        </v-img>
                                                    </a>
                                                </v-lazy>
                                                <div>
                                                    <v-card-text>
                                                        <div class="news-card-text" :style="boxHeightText">
                                                            <h3 class="mt-3" style="font-size: 2em;"><a href="https://macguyvertech.com/our-services/fractional-cto-or-ciso-strategy-and-management/" style="color:#0189E4;line-height: 1.2;">Strategy and Management</a></h3>
                                                            <p class="mt-3" :style="fontSize">Under our Strategy and Management services, we offer the expertise of a Fractional CTO/CISO to guide your organization through the complexities of modern technology and cybersecurity landscapes. This service is particularly beneficial for businesses without a full-time CTO or CISO, providing strategic leadership in technology implementation, risk management, and digital transformation. Our approach is to align technology initiatives with business goals, ensuring that every tech decision drives value and competitive advantage. We also focus on building.</p>
                                                            <div class="text-center pt-4" :style="learnBox">
                                                                <v-btn class="body-button blue" href="https://macguyvertech.com/our-services/fractional-cto-or-ciso-strategy-and-management/" title="Strategy and Management">
                                                                    <v-icon color="#ffffff" small style="margin-right:.5em;">mdi mdi-information</v-icon> Learn More
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </v-card-text>
                                                </div>
                                            </v-card>
                                        </div>
                                    </v-col>
                                    -->
                                    <v-col cols="12" md="12" lg="4" xl="4" class="pa-0 pr-3 pl-3 pb-3">
                                        <div class="pa-0 pt-2">
                                            <v-card :min-height="boxHeight" color="#ffffff" class="flexcard" height="100%" style="position: relative">
                                                <v-lazy>
                                                    <a href="https://macguyvertech.com/our-services/tailored-wordpress-and-integrations/">
                                                        <v-img alt="WEBSITES AND INTEGRATIONS: Tailored WordPress and Integrations" src="https://macguyvertech.com/wp-content/uploads/2023/12/Wordpress-Security-Issues.jpg" height="250px" width="auto">
                                                        </v-img>
                                                    </a>
                                                </v-lazy>
                                                <div>
                                                    <v-card-text>
                                                        <div class="news-card-text" :style="boxHeightText">
                                                            <h3 class="mt-3 text-center" style="font-size: 2em;"><a href="https://macguyvertech.com/our-services/tailored-wordpress-and-integrations/" style="color:#0189E4;line-height: 1.2;">Tailored WordPress Solutions</a></h3>
                                                            <p class="mt-3" :style="fontSize">In our Tailored WordPress and Integrations, we specialize in creating custom websites that perfectly align with your users’ needs and preferences. Understanding that every business has unique requirements, our approach is to craft websites that are not only visually appealing but also highly functional and user-friendly. Whether it’s an e-commerce platform, an informational site, or a complex web application, our solutions are designed to deliver an exceptional online experience. This service is ideal for businesses looking to establish a strong online presence with a website that truly represents their brand and engages their audience effectively.</p>
                                                            <div class="text-center pt-4" :style="learnBox">
                                                                <v-btn class="body-button blue" href="https://macguyvertech.com/our-services/tailored-wordpress-and-integrations/" title="Tailored WordPress and Integrations">
                                                                    <v-icon color="#ffffff" small style="margin-right:.5em;">mdi mdi-information</v-icon> Learn More
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </v-card-text>
                                                </div>
                                            </v-card>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" lass="pa-0 pr-1 pl-0 ">
                                        <div class="pa-0 pt-6 pb-6">
                                            <v-btn class="body-button blue" id="learnmore" href="https://macguyvertech.com/learnmore" target="_blank" title="Learn more about Us" small>
                                                <v-icon color="#ffffff" small style="margin-right:.5em;">fa-regular fa-file-pdf</v-icon> Download Our One-Sheet
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <FooterBar />
        </v-main>
    </v-app>
</template>
<script>
import TopNavBar from './components/TopNavBar.vue'
import FooterBar from './components/FooterBar.vue'
export default {
    name: 'AppPageSingle',
    components: { "TopNavBar": TopNavBar, "FooterBar": FooterBar },
    data: () => ({
        //debug: false,
        title: '',
        post: [],
        boxHeight: 'auto',
        boxHeightText: 'height: 400px;position:relative;',
        fontSize: 'font-size: 15px;',
        learnBox: 'position:absolute;bottom: .75em; left: 0; right:0;',
    }),
    created() {

        this.resizeWindow;
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeWindow);
    },
    mounted() {

        let id = document.querySelector('meta[name="pageid"]').content;
        //console.log(this.post.length, id);

        if (this.post.length == 0) {

            fetch('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/page/' + id)
                .then((r) => r.json())
                .then((res) => {
                    this.post = res.map(x => x)
                    this.title = this.post[0].title.rendered
                });

        }

        window.addEventListener('touchmove', this.preventDefault, { passive: false });
        window.addEventListener("resize", this.resizeWindow);

        setTimeout(function(scope) {
            scope.resizeWindow;
            console.log('resizeWindow')
        }, 1000, this);

    },
    unmounted() {
        window.removeEventListener('touchmove', this.preventDefault, { passive: false });
    },
    methods: {
        dispatchWindowResize() {
            window.dispatchEvent(new Event('resize'))
        },

        resizeWindow() {
            //console.log(e)
            if (window.innerWidth >= '1500') {
                this.boxHeight = 'auto'
                this.boxHeightText = 'height: 400px;position:relative;'
                this.fontSize = 'font-size: 15px;'
                this.learnBox = 'position:absolute;bottom: .75em; left: 0; right:0;'
            } else if (window.innerWidth >= '1469') {
                this.boxHeight = 'auto'
                this.boxHeightText = 'height: 460px;position:relative;'
                this.fontSize = 'font-size: 15px;'
                this.learnBox = 'position:absolute;bottom: .75em; left: 0; right:0;'
            } else if (window.innerWidth >= '1264') {
                this.boxHeight = 'auto'
                this.boxHeightText = 'height: 460px;position:relative;'
                this.fontSize = 'font-size: 15px;'
                this.learnBox = 'position:absolute;bottom: .75em; left: 0; right:0;'
            } else {
                this.boxHeight = 'auto'
                this.boxHeightText = 'height:100%;position:relative;'
                this.fontSize = 'font-size: 14px;'
                this.learnBox = 'margin: 0 auto;'

            }

        },
    },
}
</script>
<style>
/*
.page-body {

    background: #ffffff !important;
    min-height: 500px;
    text-align: left;
    padding-top: 1em;
}
*/
.flexcard {
    display: flex;
    flex-direction: column;
}

.flexcard .v-toolbar {
    flex: 0;
}

.video-elem {
    height: 100%;
    width: 100%;
}
</style>