<template>
    <v-app >
        <v-main touchless>
            <section class="pt-0">
                <v-container class="pb-0">
                    <v-row>
                        <v-col class="col-lg-12 pa-0 ma-0 pb-3">
                            <TopNavBar />
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <section class="pt-0">
                <div class="subpage-header-section border-top">
                    <h1 class="single-title">404: Page Not Found</h1>
                </div>
                <v-container>
                    <v-row >
                        <v-col cols="12">
                            <div class="body-text pt-0">
                                <template>
                                    <div class="page-body">
                                        <v-row>
                                            <v-col cols="12">
                                               <div style="min-height:500px">
                                                    <h4>Page Not Found</h4>
                                                    <p>Please check your browser url and try again.</p>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </template>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <FooterBar />
        </v-main>
    </v-app>
</template>

<script>
import TopNavBar from './components/TopNavBar.vue'
import FooterBar from './components/FooterBar.vue'
export default {
    name: 'App404',
    components: { "TopNavBar": TopNavBar, "FooterBar": FooterBar },
    data: () => ({
        //debug: false,
        post: [],

    }),
    created() {},
    mounted() {

    },
    methods: {


    },
}
</script>
<style>

</style>