<template>
    <v-app>
        <v-main touchless>
            <section class="pt-0">
                <v-container class="pb-0">
                    <v-row>
                        <v-col class="col-lg-12 pa-0 ma-0 pb-3 ">
                            <TopNavBar />
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <section class="pt-0">
                <div class="subpage-header-section border-top">
                    <h1 class="single-title" v-if="title">{{title}}</h1>
                </div>
                <v-container>
                    <v-row>
                        <v-col class="pa-1 pt-2 pb-2 pl-6 pr-6 d-md-6 d-lg-6 d-xl-6 d-xxl-6 v-col-md-6" cols="12">
                            <div class="pt-2 body-text">
                                <div class="pa-0 pt-2">
                                    <v-card color="#ffffff" class="flexcard" height="100%">
                                       <v-img alt="MacguyverTech AI" src="https://macguyvertech.com/wp-content/uploads/2024/10/macguyvertechai.jpg" height="350px" width="auto"></v-img>
                                    </v-card>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, index) in post" v-bind:key="index">
                        <v-col cols="12" class="pa-1 pt-6 pb-6">
                            <div class="body-text pt-0">
                                <template>
                                    <div class="page-body">
                                        <v-row>
                                            <v-col cols="12" class="pa-0">
                                                <div class="pa-1 pt-2 pb-2" v-html="item.content.rendered"></div>
                                                <div class="pa-1 pt-0 mb-4 justified-center">
                                                    <v-btn class="body-button blue" href="https://calendly.com/mactechconsultation/macguyvertech" target="_blank" title="FREE Consultation" small>
                                                        <v-icon color="#ffffff" small style="margin-right:.5em;">fas fa-user-friends</v-icon>FREE Consultation
                                                    </v-btn>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </template>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <FooterBar />
        </v-main>
    </v-app>
</template>
<script>
import TopNavBar from './components/TopNavBar.vue'
import FooterBar from './components/FooterBar.vue'
export default {
    name: 'AppPageSingle',
    components: { "TopNavBar": TopNavBar, "FooterBar": FooterBar },
    data: () => ({
        //debug: false,
        title: '',
        post: [],
    }),
    created() {},
    mounted() {

        let id = document.querySelector('meta[name="pageid"]').content;
        //console.log(this.post.length, id);

        if (this.post.length == 0) {

            fetch('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/page/' + id)
                .then((r) => r.json())
                .then((res) => {
                    this.post = res.map(x => x)
                    this.title = this.post[0].title.rendered
                });

        }

    },
    methods: {},
}
</script>
<style>
h2.smallertext {
    font-size: 1.9em;
    line-height: 1;
    margin-bottom: 15px;
    margin-top: 15px;
}

@media screen and (max-width: 1190px) {
    h2.smallertext {
        font-size: 1.5em;
        line-height: 1;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    h1.single-title {

        font-size: 2em !important;
    }
}
</style>