<template>
    <slide-y-down-transition>
        <div v-if="menuIsVisible" class="vnb__popup">
            <div class="vnb__popup__top">
                <a :href="siteurl" title="MacguyverTech">
                <img v-if="options.showBrandImageInMobilePopup && options.brandImage" width="129px" height="50px" src="../../../../images/macguyvertech_logo_notext-sm.png" alt="MacguyverTech: Home" class="vnb-image vnb__popup__top__image" /></a>

                <button class="vnb__popup__top__close-button" @click="closeButtonClicked" aria-label="Close Button" title="Close" :aria-expanded="menuIsVisible ? 'true' : 'false'" style="right: -40px; top:20px;">
                    <svg height="100pt" preserveAspectRatio="xMidYMid meet" viewBox="0 0 100 100" width="100pt" xmlns="http://www.w3.org/2000/svg" class="vnb__popup__top__close-button__image" style="fill: #0085ff;">
                        <title>Close button</title>
                        <path d="m42 967c-12-13-22-27-22-33 0-5 93-102 207-216l208-208-208-208c-114-114-207-214-207-223 0-8 11-26 25-39l26-24 214 214 215 215 215-215 214-214 26 24c14 13 25 28 25 34s-92 103-205 216-205 209-205 215 92 102 205 215 205 210 205 216c0 12-42 54-55 54-5 0-104-94-220-210l-210-210-210 210c-115 116-212 210-216 210-3 0-15-10-27-23z" transform="matrix(.1 0 0 -.1 0 100)" />
                    </svg>
                </button>
            </div>
            <div class="vnb__popup__bottom">
                <div v-if="!!this.$slots['custom-section']" class="vnb__popup__bottom__custom-section">
                    <slot name="custom-section"></slot>
                </div>
                <ul class="vnb__popup__bottom__menu-options">
                    <li v-for="(option, index) in combinedMenuItems" :key="index" class="vnb__popup__bottom__menu-options__option">
                        <div v-if="!option.subMenuOptions">
                            <v-hover v-slot="{ hover }" open-delay="20">
                                <a :href="option.path" :style="{ 'text-decoration': hover ? 'underline' : 'none', 'color': hover ? '#1A83FF' : option.activeColor,'display': 'block' }"> {{ option.text }}</a>
                            </v-hover>
                        </div>
                        <div v-else>
                            <v-hover v-slot="{ hover }" open-delay="20">
                                <a :href="option.path" :style="{ 'text-decoration': hover ? 'underline' : 'none', 'color': hover ? '#1A83FF' : option.activeColor,'padding-top': '5px'}"> {{ option.text }}</a>
                            </v-hover>
                        </div>
                        <div class="vnb__popup__bottom__sub-menu-options" style="padding-left: 20px;">
                            <div v-for="(subOption, index) in option.subMenuOptions" :key="index" class="vnb__popup__bottom__sub-menu-options__option">
                                <v-hover v-slot="{ hover }" open-delay="20">
                                <a :href="subOption.path"> <span :style="{ 'text-decoration': hover ? 'underline' : 'none', 'color': hover ? rgb(26, 131, 255) : '#000000','display': 'block' }">{{ subOption.text }}</span>
                                    <span class="vnb__popup__bottom__sub-menu-options__option__link__sub-text">{{ subOption.subText }}</span>
                                </a></v-hover>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </slide-y-down-transition>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";

export default {
    name: "popup",
    props: {
        options: {
            type: Object,
            required: true
        },
        menuIsVisible: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {

            siteurl: 'https://' + process.env.VUE_APP_HOST_NAME + '/',
            
        };
    },
    computed: {
        combinedMenuItems() {
            let combinedArray = this.options.menuOptionsLeft.concat(
                this.options.menuOptionsRight
            );
            return combinedArray;
        }
    },
    methods: {
        closeButtonClicked() {
            this.$emit("close-button-clicked");
        },
        itemSelected(option) {
            this.$emit("vnb-item-clicked", option.text);
            this.closeButtonClicked();
        },
        
    },
    components: {
        //DynamicLink,
        SlideYDownTransition
    }
};
</script>
<style lang="scss">
@import "../assets/css/main.scss";

.vnb {
    &__popup {
        background: $white;
        position: absolute;
        left: 10px;
        top: 10px;
        right: 10px;
        display: flex;
        flex-direction: column;
        perspective: 2000px;
        box-shadow: $box-shadow;
        margin-bottom: 20px;
        z-index: 100000;

        &__top {
            padding: 15px 24px 0;
            border-top: 1px solid $white;
            border-left: 1px solid $white;
            border-right: 1px solid $white;
            border-top-right-radius: 6px;
            border-top-left-radius: 6px;

            &__image {
                max-height: 27px;
                margin-bottom: 5px;
            }

            &__close-button {
                position: absolute;
                top: 20px;
                right: -40px;
                cursor: pointer;
                border: 0;
                background: transparent;

                &:hover {
                    opacity: 0.75;
                    color: #ffffff;
                }

                &__image {
                    max-height: 15px;
                }
            }
        }

        &__bottom {
            background: transparent;
            padding: 10px 0 10px;
            border-left: 1px solid $white;
            border-right: 1px solid $white;
            border-bottom: 1px solid $white;
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;

            &__custom-section {
                padding: 12px 24px;
            }

            &__menu-options {
                list-style-type: none;
                padding-left: 0;
                display: flex;
                flex-direction: column;

                &__option {
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }

                    &__link {
                        padding: 12px 24px;
                        color: "#ffffff";
                        font-weight: 500;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        transition: color 0.2s ease-in;
                        width: 100%;

                        &:hover {
                            color: "#0085FF";
                            text-decoration: none;
                            background: transparent;
                            border-left: 0;
                        }

                        &--no-highlight {
                            padding: 12px 24px 6px;
                            font-weight: 400;
                            font-size: 0.8rem;
                            color: "#ffffff";

                            &:hover {
                                color: "#0085FF";
                                background: transparent;
                                border-left: 0;
                            }
                        }

                        &__icon {
                            svg {
                                max-height: 24px;
                                max-width: 24px;
                            }

                            &--left {
                                margin-right: 5px;
                            }

                            &--right {
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }

            &__sub-menu-options {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
                font-size: 0.9rem;
                padding-left: 20px;

                &__option {
                    width: 100%;

                    &__link {
                        padding: 6px 24px;
                        width: 100%;
                        display: block;
                        color: "#ffffff";
                        font-weight: 500;
                        border-left: 0;

                        &:hover {
                            color: "#0085FF";
                            text-decoration: none;
                            background: transparent;
                            border-left: 0;
                        }

                        &__sub-text {
                            margin-top: 5px;
                            display: block;
                            font-size: 0.75rem;
                            color: lighten($black, 35%);
                        }
                    }
                }
            }
        }
    }
}

.vnb-image {
    max-width: 100%;
    height: auto;
}

.vnb__popup__bottom__menu-options__option__link:hover {
    text-decoration: underline;
    background: transparent !important;
    border-left: 0 !important;
}

.vnb__popup__bottom__sub-menu-options__option__link:hover {
    text-decoration: underline;
    background: transparent;
    border-left: 0;
}
</style>