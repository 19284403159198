<template>
    <v-app>
        <v-main touchless>
            <section class="pt-0">
                <v-container class="pb-0">
                    <v-row>
                        <v-col class="col-lg-12 pa-0 ma-0 pb-3 ">
                            <TopNavBar />
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <section class="pt-0">
                <div class="subpage-header-section border-top">
                    <h1 class="single-title">{{this.title}}</h1>
                </div>
                <v-container>
                    <v-row v-for="(item, index) in post" v-bind:key="index">
                        <v-col cols="12">
                            <div class="body-text pt-0">
                                <template>
                                    <div class="page-body">
                                        <v-row>
                                            <v-col cols="12" class="pa-0">
                                                <div class="">
                                                    <div class="ourwork-subtitle">
                                                        <h2 style="padding-bottom: .5em;padding-top: 1em;">We love to create beautiful applications that are <span class="orange-text">easy to use.</span></h2>
                                                    </div>
                                                    <div class="page-body-clients" style="padding-top:0;" v-for="(client, i) in item.acf.our_work" v-bind:key="i">
                                                        <v-container class="pb-0">
                                                            <v-row>
                                                                <v-col cols="12">
                                                                    <div class="body-text pt-0 page-body-inner ">
                                                                        <div class="">
                                                                            <h3 class="orange-text mx-auto-ourwork" ><span style="color: #0189E4;">{{ client.title }}</span></h3>
                                                                        </div>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col class="col-lg-6 col-md-6 col-sm-12">
                                                                    <div class="body-text pt-0 page-body-inner ">
                                                                        <div class="">
                                                                            <v-img class="mx-auto-ourwork" :src="client.image"></v-img>
                                                                        </div>
                                                                    </div>
                                                                </v-col>
                                                                <v-col class="col-lg-6 col-md-6 col-sm-12">
                                                                    <div class="body-text pt-0 page-body-inner ">
                                                                        <div class="mx-auto-ourwork" v-html="client.description"></div>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </template>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <div class="pa-6 pt-0 mb-4 body-text">
                                <v-btn class="body-button blue" id="learnmore" href="https://macguyvertech.com/learnmore" target="_blank" title="Learn more about Us" small>
                                    <v-icon color="#ffffff" small style="margin-right:.5em;">fa-regular fa-file-pdf</v-icon> Learn more about Us
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <FooterBar />
        </v-main>
    </v-app>
</template>
<script>
import TopNavBar from './components/TopNavBar.vue'
import FooterBar from './components/FooterBar.vue'
export default {
    name: 'AppPageSingle',
    components: { "TopNavBar": TopNavBar, "FooterBar": FooterBar },
    data: () => ({
        //debug: false,
        post: [],
        title: 'Our Work', //this.post[0].title.rendered ? this.post[0].title.rendered : ,
        loaded: false,
    }),
    created() {},
    beforeMount() {

        let id = document.querySelector('meta[name="pageid"]').content;
        //console.log(this.post.length, id);

        if (this.post.length == 0 && this.loaded==false) {

            fetch('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/page/' + id)
                .then((r) => r.json())
                .then((res) => this.post = res.map(x => x));

            this.loaded=true;

        }

    },
    methods: {},
}
</script>
<style>
</style>