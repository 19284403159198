import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const opts = {
    theme: {

        themes: {
            light: {
                primary: '#EE7D40',
                secondary: '#0085ff',
                accent: '#FF6700',
                error: '#FF5F42',
                info: '#363032',
                success: '#4CAF50',
                warning: '#D9D9D9',
            },
        },
        dark: false,
    },
}

export default new Vuetify(opts)