<template>
    <v-app>
        <v-main touchless>
            <section class="pt-0">
                <v-container class="pb-0">
                    <v-row>
                        <v-col class="col-lg-12 pa-0 ma-0 pb-3 ">
                            <TopNavBar />
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <section class="pt-0">
                <div class="subpage-header-section border-top">
                    <h1 class="single-title">Blog</h1>
                </div>
                <v-container class="pt-2">
                    <v-row v-for="(item, index) in post" v-bind:key="index">
                        <v-col class="col-lg-12 pt-0">
                            <div class="body-text pt-0">
                                <div class="page-body">
                                    <div class="subpage-header-section-orange mt-2">
                                        <h2 class="single-title">{{item.title.rendered}}</h2>
                                        <p class="text-center" v-html="item.date"></p>
                                    </div>
                                    <div>
                                        <v-img style="max-height: 300px;" :style="{ height: imageHeight + 'px' }" :src="item.featured_media_img" class="featured_media">
                                        </v-img>
                                    </div>
                                    <div>
                                        <v-chip-group class="justify-end" column>
                                            <div item.categories v-for="(cat, i) in item.categories_data" v-bind:key="i">
                                                <v-chip color="#1E1E1E" :href="cat.category_url" class="catchip" outlined>{{ cat.name }}</v-chip>
                                            </div>
                                        </v-chip-group>
                                    </div>
                                    <div class="pa-2" v-html="item.content.rendered"></div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <section class="pt-0 pb-4 mt-4" style="background-color: #D9D9D9;">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <div class="text-center">
                                <h2>Categories</h2>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="flex-direction-row-tb flex-direction-column-m">
                        <v-col class="col-lg-12">
                            <template>
                                <v-chip-group class="justify-end" column>
                                    <div item.categories v-for="(cat, i) in cats" v-bind:key="i">
                                        <v-chip color="#1E1E1E" :href="cat.category_url" class="catchipOther" outlined large>{{ cat.name }} ({{ cat.category_count }})</v-chip>
                                    </div>
                                </v-chip-group>
                            </template>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <FooterBar />
        </v-main>
    </v-app>
</template>
<script>
import TopNavBar from './components/TopNavBar.vue'
import FooterBar from './components/FooterBar.vue'
import axios from 'axios'
export default {
    name: 'AppPageSingle',
    components: { "TopNavBar": TopNavBar, "FooterBar": FooterBar },
    data: () => ({
        //debug: false,
        post: [],
        imageHeight: null,
        title: '',
        cats: [],
        date: '',
    }),
    created() {},
    mounted() {

        let id = document.querySelector('meta[name="pageid"]').content;
        console.log(this.post.length, id);

        if (this.post.length == 0) {

            axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/page/' + id)
                .then(res => {
                    console.log(res.data[0]);
                    this.post = res.data;
                    this.title = res.data[0].title.rendered
                    this.date = res.data[0].date

                }, error => {
                    console.log(error);
                });

            axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/categories/1')
                .then(res => {

                    this.cats = res.data;

                }, error => {
                    console.log(error);
                });

            this.mounted = true

            this.setheight()
            window.addEventListener('resize', this.setheight)

        }

        //let catname = document.querySelector('meta[name="categoryname"]').content;
        //this.name = catname;

    },
    methods: {
        setheight() {

            //let image = event.target;    
            this.imageHeight = window.innerWidth * .2;

            if (window.innerWidth > 960)
                this.imageHeight = 250

            if (this.imageHeight < 150)
                this.imageHeight = 150

            console.log(window.innerWidth, this.imageHeight);
        }

    },
}
</script>
<style>
/*
.page-body {

    background: #ffffff !important;
    min-height: 500px;
    text-align: left;
    padding-top: 1em;
}
*/
</style>