<template>
    <v-app>
        <v-main touchless>
            <section class="pt-0">
                <v-container class="pb-0">
                    <v-row>
                        <v-col class="col-lg-12 pa-0 ma-0 pb-3 ">
                            <TopNavBar />
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <section class="pt-0">
                <div class="subpage-header-section border-top">
                    <h1 class="single-title">Careers</h1>
                </div>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <div class="body-text pt-0">
                                <template>
                                    <v-row>
                                        <v-col cols="12">
                                            <h2 class="text-center" style="margin-bottom: 1em;">MacguyverTech is <span class="orange-text">HIRING</span></h2>
                                        </v-col>
                                    </v-row>
                                    <div v-for="(item, index) in post" v-bind:key="index">
                                        <!-- Job Post 1 -->
                                        <v-row>
                                            <v-col cols="12" sm="12" md="12" class="border-bottom-thick pb-2" >
                                                <v-card color="#EFEFEF" dark width="650" style="margin: 0 auto;" class="text-center mb-2">
                                                    <div style="width:500px; margin: 0 auto;">
                                                        <v-card-title class="careers-title" >
                                                            <v-lazy>
                                                                <img class="mr-3 careers-icon" src="../images/careers/fullstackdeveloper.svg" />
                                                            </v-lazy> {{ item.acf.job_header_1 }}
                                                        </v-card-title>
                                                    </div>
                                                </v-card>
                                                <v-col cols="12">
                                                    <div v-html="item.acf.job_description_1"></div>
                                                    <div class="text-center">
                                                        <v-btn class="body-button blue" style="padding: 1em 2em" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScbTs_g-H4yP_xi0hxKhv5EAFNpHHEmAikSCw6VRY-SKKdbiA/viewform">
                                                         <v-icon color="#ffffff" small style="margin-right:.5em;">fa-solid fa-caret-down</v-icon> Apply Now
                                                        </v-btn>
                                                    </div>
                                                </v-col>
                                            </v-col>
                                        </v-row>
                                        <!-- Job Post 2 -->
                                        <!-- <v-row >
                                            <v-col cols="12" sm="12" md="12" class="border-bottom-thick pb-2" >
                                                <v-card color="#EFEFEF" dark width="650" style="margin: 0 auto;" class="text-center mb-2">
                                                    <div style="width:500px; margin: 0 auto;">
                                                        <v-card-title class="careers-title" >
                                                            <v-lazy>
                                                                <img class="mr-3 careers-icon" src="../images/careers/frontenddeveloper.svg" />
                                                            </v-lazy> {{ item.acf.job_header_2 }}
                                                        </v-card-title>
                                                    </div>
                                                </v-card>
                                                <v-col cols="12">
                                                    <div v-html="item.acf.job_description_2"></div>
                                                    <div class="text-center">
                                                        <v-btn style="padding: 1em 2em" href="https://forms.gle/E4rn4LKYvKRqVTeG8" target="_blank" >
                                                            <v-icon color="#ffffff" small style="margin-right:.5em;">fa-solid fa-caret-down</v-icon>  Apply Now
                                                        </v-btn>
                                                    </div>
                                                </v-col>
                                            </v-col>
                                        </v-row> -->
                                        <!-- Job Post 3 -->
                                        <!-- <v-row >
                                            <v-col cols="12" sm="12" md="12" class="" > 
                                                <v-card color="#EFEFEF" dark width="750" style="margin: 0 auto;" class="text-center mb-2">
                                                    <div style="width:650px; margin: 0 auto;">
                                                        <v-card-title class="careers-title">
                                                            <v-lazy>
                                                                <img class="mr-3 careers-icon" src="../images/careers/backenddeveloper.svg"  />
                                                            </v-lazy> {{ item.acf.job_header_3 }}
                                                        </v-card-title>
                                                    </div>
                                                </v-card>
                                                <v-col cols="12">
                                                    <div v-html="item.acf.job_description_3"></div>
                                                    <div class="text-center">
                                                        <v-btn style="padding: 1em 2em" href="https://docs.google.com/forms/d/e/1FAIpQLScbTs_g-H4yP_xi0hxKhv5EAFNpHHEmAikSCw6VRY-SKKdbiA/viewform" target="_blank" >
                                                           <v-icon color="#ffffff" small style="margin-right:.5em;">fa-solid fa-caret-down</v-icon>  Apply Now 
                                                        </v-btn>
                                                    </div>
                                                </v-col>
                                            </v-col>
                                        </v-row> -->
                                    </div>
                                </template>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <FooterBar />
        </v-main>
    </v-app>
</template>
<script>
import TopNavBar from './components/TopNavBar.vue'
import FooterBar from './components/FooterBar.vue'
import axios from 'axios'
export default {
    name: 'AppCareers',
    components: { "TopNavBar": TopNavBar, "FooterBar": FooterBar },
    data: () => ({
        //debug: false,
        isHidden: false,
        isHidden2: false,
        isHidden3: false,
        isHidden4: false,
        isHidden5: false,
        deg: 0,
        deg2: 0,
        deg3: 0,
        deg4: 0,
        deg5: 0,

        post: [],
    }),
    created() {},
    mounted() {

        let id = document.querySelector('meta[name="pageid"]').content;
        //console.log(this.post.length, id);

        if (this.post.length == 0) {

            axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/page/' + id)
            .then(res => {
                //console.log(response.data);
                this.post = res.data;

            }, error => {
                console.log(error);
            });

        }

    },
    methods: {

        rotation(d) {

            //console.log(d);
            switch (d) {
                default:
                    //console.log(d);
                    break;

                case 1:

                    this.deg += 180;

                    break;
                case 2:

                    this.deg2 += 180;

                    break;
                case 3:

                    this.deg3 += 180;
                    break;
                case 4:

                    this.deg4 += 180;

                    break;
                case 5:

                    this.deg5 += 180;

                    break;
            }
        },


    },
}
</script>
<style>
</style>