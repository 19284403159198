<template>
  <section class="footer" style="border-top: 4px solid #fd9654">
    <div class="sub-section">
      <v-container>
        <v-row>
          <v-col class="col-lg-6 col-md-6 col-sm-12 col-xs-12 footer-inner">
            <v-row>
              <v-col cols="12" class="pt-2">
                <div
                  class="footer-border-bottom mb-6"
                  style="border-bottom: 2px solid #fff"
                >
                  <h2 class="white-text">Our Partners</h2>
                </div>
                <div
                  class="footer-border-bottom pb-2"
                  style="border-bottom: 2px solid #fff"
                >
                  <p>
                    <a
                      class="mr-3"
                      title="Codesealer"
                      href="https://codesealer.com/"
                      target="_blank"
                      ><img
                        alt="Codesealer Logo"
                        class="CodesealerLogo"
                        src="./vue-navigation-bar/assets/images/codeSealerLogowhite.png"
                        height="75"
                    /></a>

                    <a
                      class="mr-3"
                      title="SkippyAI"
                      href="https://skipflo.systems/"
                      target="_blank"
                      ><img
                        alt="SkippyAI"
                        class="CodesealerLogo"
                        src="./vue-navigation-bar/assets/images/SkippyAI-logo.png"
                        height="75"
                    /></a>
                    <a
                      class="mr-3"
                      title="FBI Infragard"
                      href="https://www.infragard.org/"
                      target="_blank"
                      ><img
                        alt="FBI Infragard"
                        class="CodesealerLogo"
                        src="./vue-navigation-bar/assets/images/InfraGardLogo.png"
                        height="75"
                    /></a>
                  </p>
                </div>
              </v-col>
              <v-col cols="12" class="pr-8 pb-0 pt-6">
                <p class="white-text pt-4 pb-0 pt-sm-0 pt-xs-0">
                  Let us help you secure your infrastructure. MacguyverTech
                  partners with MSPs and cybersecurity firms to fix hacked apps,
                  outdated software, and data issues with incident response, AI
                  scans, and code review.
                </p></v-col
              >
              <v-col
                cols="12"
                class="col-lg-6 col-md-12 col-sm-12 col-xs-12 pb-0"
                style="postion: relative"
              >
                <p class="white-text">
                  <v-icon class="white-text"> mdi-email-outline </v-icon>
                  <a
                    title="MacguyverTech - Contact"
                    href="mailto:contact@macguyvertech.com"
                    class="white-text"
                  >
                    contact@macguyvertech.com
                    <span style="font-size: 0">MacguyverTech - Contact</span>
                  </a>
                </p>
                <p class="white-text pt-0">
                  <v-icon class="white-text"> mdi-email-outline </v-icon>
                  <a
                    title="MacguyverTech - Support"
                    href="mailto:support@macguyvertech.com"
                    class="white-text"
                  >
                    support@macguyvertech.com
                    <span style="font-size: 0">MacguyverTech - Support</span></a
                  >
                </p>
                <p class="white-text pt-0 pb-6">
                  <v-icon class="white-text"> mdi-cellphone </v-icon>
                  <a
                    title="MacguyverTech - Phone"
                    href="tel:+12672257520"
                    class="white-text"
                    >(267) 225-7520
                    <span style="font-size: 0">MacguyverTech - Phone</span></a
                  >
                </p>
              </v-col>

              <v-col
                cols="12"
                class="col-lg-6 col-md-12 col-sm-12 col-xs-12 pb-0"
              >
                <p class="white-text pt-0">
                  <a
                    title="MacguyverTech - LinnkedIn"
                    href="https://www.linkedin.com/company/macguyvertech/"
                    class="white-text pr-3"
                  >
                    <v-icon class="white-text"> fab fa-linkedin-in </v-icon>
                    <span style="font-size: 0">MacguyverTech - LinnkedIn</span>
                  </a>
                  <a
                    title="MacguyverTech - Twitter"
                    href="https://twitter.com/MacguyverTech01"
                    class="white-text pr-3"
                  >
                    <v-icon class="white-text"> fab fa-twitter </v-icon>
                    <span style="font-size: 0">MacguyverTech - Twitter</span>
                  </a>
                  <a
                    title="MacguyverTech - Tiktok"
                    href="https://www.tiktok.com/@macguyvertech"
                    class="white-text pr-3"
                  >
                    <v-icon class="white-text"> fab fa-tiktok </v-icon>
                    <span style="font-size: 0">MacguyverTech - Tiktok</span>
                  </a>
                  <a
                    title="MacguyverTech - Youtube"
                    href="https://www.youtube.com/c/MacguyverTech"
                    class="white-text pr-3"
                  >
                    <v-icon class="white-text"> fab fa-youtube </v-icon>
                    <span style="font-size: 0">MacguyverTech - Youtube</span>
                  </a>
                  <a
                    title="MacguyverTech - Instagram"
                    href="https://www.instagram.com/macguyvertech/"
                    class="white-text pr-3"
                  >
                    <v-icon class="white-text"> fab fa-instagram </v-icon>
                    <span style="font-size: 0">MacguyverTech - Instagram</span>
                  </a>
                  <a
                    title="MacguyverTech - Medium"
                    href="https://macguyvertech.medium.com/"
                    class="white-text"
                  >
                    <v-icon class="white-text"> fab fa-medium </v-icon>
                    <span style="font-size: 0">MacguyverTech - Medium</span>
                  </a>
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="col-lg-6 col-md-6 col-sm-12 col-xs-12 footer-inner">
            <v-form ref="form" lazy-validation>
              <v-row class="ma-0 pa-0">
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  class="form-wrapper ma-0 pa-2"
                >
                  <div v-if="formCompleted">
                    <v-alert type="success">
                      <p class="white-text">
                        Success! Thank you for contacting MacguyverTech. We will
                        get back to you as soon as we can. Have a great day!
                      </p>
                    </v-alert>
                  </div>
                  <div v-else>
                    <v-progress-circular
                      v-if="showmoreActive"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                    <div v-else>
                      <v-row>
                        <v-col cols="12" class="pa-0">
                          <div
                            class="footer-border-bottom mb-6"
                            style="border-bottom: 2px solid #fff"
                          >
                            <h2 class="white-text">Let’s Talk!</h2>
                          </div>
                          <label for="name" class="white-text pl-0 pb-0"
                            >NAME</label
                          >
                          <v-text-field
                            id="name"
                            solo
                            label=""
                            v-model="opts.name"
                            :rules="nameRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pa-0">
                          <label for="email" class="white-text pl-0 pb-0"
                            >EMAIL</label
                          >
                          <v-text-field
                            id="email"
                            solo
                            label=""
                            v-model="opts.email"
                            :rules="emailRules"
                          ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" class="pa-0">
                          <label for="subject" class="white-text pl-0 pb-0"
                            >SUBJECT</label
                          >
                          <v-select
                            id="subject"
                            multiple
                            :items="options"
                            label=""
                            solo
                            v-model="opts.subject"
                          ></v-select>
                        </v-col>-->
                        <v-col cols="12" class="pa-0">
                          <label for="message" class="white-text pl-0 pb-0"
                            >MESSAGE</label
                          >
                          <v-textarea
                            id="message"
                            solo
                            class="textarea"
                            dense
                            label=""
                            v-model="opts.message"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" class="pa-0 pt-5">
                          <v-btn
                            @click="submitForm()"
                            color="primary"
                            large
                            class="mt-0 blue"
                          >
                            <v-icon
                              color="#ffffff"
                              small
                              style="margin-right: 0.5em"
                              >fas fa-user-friends</v-icon
                            >
                            Let's Talk!
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </section>
</template>
<script>
export default {
  name: "FooterNavBar",
  data() {
    return {
      appTitle: "MacguyverTech",
      sidebar: false,
      opts: {
        name: "",
        subject: ["Custom Software Development"],
        email: "",
        message: "",
        hash: "",
      },
      options: [
        "Custom Software Development",
        "Cyber Security Penetration Testing",
        "Code Review And Remediation",
        "Careers",
        "Other",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Valid E-mail is required",
      ],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length >= 3) || "Name must be greater than 3 characters",
      ],
      showmoreActive: false,
      formCompleted: false,
    };
  },
  mounted() {},
  methods: {
    resetForm() {
      this.formCompleted = false;
      this.showmoreActive = false;
      this.opts = {
        name: "",
        subject: ["Custom Software Development"],
        email: "",
        message: "",
        hash: "",
      };
    },

    hasher(str, seed = 0) {
      let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed;
      for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
      }
      h1 =
        Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
        Math.imul(h2 ^ (h2 >>> 13), 3266489909);
      h2 =
        Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
        Math.imul(h1 ^ (h1 >>> 13), 3266489909);
      return 4294967296 * (2097151 & h2) + (h1 >>> 0);
    },

    submitForm() {
      if (this.$refs.form.validate()) {
        this.showmoreActive = true;

        this.opts.hash = this.hasher(Math.random());
        //console.log(this.opts);

        var t = fetch(
          "https://" +
            process.env.VUE_APP_HOST_NAME +
            "/index.php/wp-json/api/v1/send",
          {
            method: "post",
            headers: {
              "x-requested-with": "XMLHttpRequest",
            },
            body: JSON.stringify(this.opts),
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function () {
            //console.log('Data:', data);
            //alert('Thank you for contacting MacguyverTech. We will get back to you as soon as we can.');
            //window.location.reload(true);
            return true;
          });

        if (t) {
          setTimeout(() => {
            this.opts = {
              name: "",
              subject: ["Custom Software Development"],
              email: "",
              message: "",
              hash: "",
            };
            this.showmoreActive = false;
            this.formCompleted = true;
            //setTimeout(() => { this.dialog = false; }, 3000);
            setTimeout(() => {
              this.resetForm();
            }, 5000);
          }, 1000);
        }
      }
    },
  },
};
</script>
<style></style>
