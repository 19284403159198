<template>
    <v-app>
        <v-main touchless>
            <section class="homesection-hello pt-0">
                <v-container class="pb-0" style="overflow: hidden;">
                    <v-row>
                        <v-col class="col-lg-12 pa-0 ma-0 border-bottom">
                            <TopNavBar />
                        </v-col>
                    </v-row>
                    <v-row class="pt-6 pt-sm-0 pt-xs-0">
                        <v-col class="col-lg-12 pa-0 homesection-hello-img" v-bind:style="{ 'background-image': 'url(' + selectedImage + ')' }" style="position: relative;margin: 0em;margin-top: 1em;">
                            <v-row class="homesection-hello-msg">
                                <v-col class="col-lg-6 col-md-6 pa-1 pr-2 pb-2 ma-0 home-top-text">
                                    <h1 class="mb-0 mt-0 pb-1" style="line-height: 1.25;margin-bottom: .5em;"> <span>Macguyver</span><span style="color: #E75F03;">Tech</span></h1>
                                    <h2 class="mb-1 mt-0" style="line-height: 1.25;font-size: 2em;">Application Security</h2>
                                    <p class="mt-4" style="font-size: 1.25em;font-weight: bold;">
                                        Working with technical partners (<span style="color: #0189E4;">MSPs</span>, <span style="color: #0189E4;">MSSPs</span>, <span style="color: #0189E4;">Cybersecurity</span> and
                                        <span style="color: #0189E4;">Software Development</span> companies) and their clients to <span style="color: #E75F03;">innovate</span>, <span style="color: #E75F03;">protect</span>
                                        and <span style="color: #E75F03;">secure</span> American Infrastructure since 2014.</p>
                                    <h3 class="mt-1" style="font-weight: bold;"><span style="color: #E75F03;font-weight: bold;">Pain Points We Solve</span></h3>
                                    <p class="mt-2">
                                        <ul>
                                            <li class="pt-1" style="font-weight: bold;">
                                                Hacked or compromised applications (apps)</li>
                                            <li class="pt-1" style="font-weight: bold;">
                                                Software that is too old or uses outdated languages</li>
                                            <li class="pt-1" style="font-weight: bold;">
                                                Client data problems AKA data dumpster fires</li>
                                        </ul>
                                        <h3 class="mt-2" style="font-weight: bold;"><span style="color: #E75F03;font-weight: bold;">How We Can Help</span></h3>
                                        <p class="mt-2" style="font-weight: bold;">Align with us to keep your client's applications secure and efficient. We offer comprehensive remediation work to ensure your client's applications are safe.</p>
                                        <p class="mt-2">
                                            <ul>
                                                <li class="pt-1" style="font-weight: bold;">
                                                    Quickly work with tech, legal and insurance partners to:
                                                    <ul>
                                                        <li class="pt-1" style="font-weight: bold;">
                                                            Clearly identify breach scope and action plan.</li>
                                                        <li class="pt-1" style="font-weight: bold;">
                                                            Secure affected environment.</li>
                                                        <li class="pt-1" style="font-weight: bold;">
                                                            Remediate software security risks (CVEs) in conjunction with third party network remediation.</li>
                                                    </ul>
                                                </li>
                                                <li class="pt-1" style="font-weight: bold;">
                                                    Create custom secure solutions to solve complex data problems.</li>
                                                <li class="pt-1" style="font-weight: bold;">
                                                    Solutions that comply with all major standards (PII, HIPAA, PCI, PHI, etc.) to secure sensitive information</li>
                                            </ul>
                                </v-col>
                            </v-row>
                            <img alt="MacguyverTech - Grid" class="home-img-grid sm-hide" src="../images/home/grid.svg" height="100" />
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <section style="position: relative;overflow: hidden;height: 2.4em;">
                <div class="ticker-wrap">
                    <div class="ticker">
                        <div class="ticker__item">31% of Companies Detect Software Exploits: A significant portion of businesses face attacks exploiting software vulnerabilities.</div>
                        <div class="ticker__item">75% of Applications Have Security Flaws: Most applications contain vulnerabilities, with a quarter being highly severe.</div>
                        <div class="ticker__item">84% of Companies Have High-Risk Vulnerabilities: Many businesses have critical vulnerabilities that are easily exploitable.</div>
                        <div class="ticker__item">Cost of Cybercrime: The global annual cost of cybercrime is estimated at $6 trillion, projected to reach $10.5 trillion by 2025.</div>
                        <div class="ticker__item">Cost per Breach: Data breaches cost businesses an average of $4.45 million in 2023.
                        </div>
                    </div>
                </div>
            </section>
            <section class="homesection-whatwedo">
                <div class="sub-section">
                    <v-container>
                        <v-row>
                            <v-col class="col-lg-6 col-md-12 pa-0 ma-0" cols="12">
                                <v-row class="pb-2">
                                    <v-col class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pb-1" cols="12">
                                        <h2 class="white-text pt-0" id="wwd" style="font-size: 2.5em;">
                                            Our <span style="color: #E75F03;">Services</span>
                                        </h2>
                                    </v-col>
                                    <v-col class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pb-0">
                                        <div class="border-white-top pt-4">
                                            <h3 class="white-text home-h3" style="color: #E75F03;font-size: 1.8rem;"><span >AI Driven Cybersecurity Assessment & Remediation</span></h3>
                                            <p class="white-text">
                                                <ul>
                                                    <li class="pt-1"><span style="font-weight: bold; font-size: 1.35em;">Incident Response</span>
                                                        <ul class="pb-2">
                                                            <li class="pt-1">Quickly work with tech, legal and insurance partners to identify breach scope and action plan.
                                                                Secure affected environment.</li>
                                                            <li class="pt-1">Remediate software security risks (CVEs) in conjunction with third party network remediation.
                                                            </li>
                                                            <li class="pt-1">AI Assisted Scanning and Reports: Utilize AI for comprehensive security scans and detailed reports.
                                                            </li>
                                                            <li class="pt-1">Application Layer Penetration Testing</li>
                                                            <li class="pt-1">Advanced Code Review (including CICD Pipeline verification)</li>
                                                        </ul>
                                                    </li>
                                                    <li class="pt-1"><span style="font-weight: bold; font-size: 1.35em;">Static Code Analysis</span>
                                                        <ul>
                                                            <li class="pt-1">SCA (Software Composition Analysis) & SBOM (Software Bill of Materials)
                                                                Secure affected environment.</li>
                                                            <li class="pt-1">Auto Remediation with Software Developer Oversight: Quickly fix issues with the combined power of AI and human expertise.
                                                            </li>
                                                            <li class="pt-1">Software Cybersecurity Architecture and Planning: Receive expert advice to strengthen your security posture.
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </p>
                                        </div>
                                        <div class="border-white-top pt-4 mt-6">
                                            <h3 class="white-text home-h3" style="color: #E75F03;font-size: 1.8rem;"><span >AI Assisted Software Development & Automation</span></h3>
                                            <p class="white-text">
                                                <ul>
                                                    <li class="pt-1">Faster Turnaround for Builds: Accelerate your development cycles with AI-driven efficiencies.</li>
                                                    <li class="pt-1">
                                                        Automate Tedious Tasks: Let AI handle repetitive tasks, freeing up your team for more strategic work.</li>
                                                    <li class="pt-1">
                                                        Find and Fix Issues Faster: Leverage AI to identify and resolve issues quickly and effectively.</li>
                                                    <li class="pt-1">30% Cost Reduction in Projects: Achieve significant cost savings with our AI-powered solutions.</li>
                                                    <li class="pt-1">Auto Integrate Any API or SaaS Service Quickly: Seamlessly connect and integrate with various services using AI.</li>
                                                    <li class="pt-1">Old Legacy Systems: Modernize and secure your outdated systems with our advanced solutions.</li>
                                                </ul>
                                            </p>
                                        </div>
                                        <div class="text-center pb-2 letstalk-button pt-6">
                                            <v-btn class="body-button home-letstalk blue" v-bind="attrs" target="_blank" href="https://calendly.com/mactechconsultation/macguyvertech" title="Let's talk">
                                                <v-icon color="#ffffff" small style="margin-right:.5em;">fas fa-user-friends</v-icon> Free Consultation
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="col-lg-6 col-md-12 col-sm-12 col-xs-12 pa-0 ma-0" cols="12">
                                <v-img alt="MacguyverTech - Code Review" max-height="795" max-width="931" src="../images/codereview.png"></v-img>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </section>
            <section class="homesection-inthenews" style="background-color: #e2eaf3;">
                <div class="sub-section" style="padding-bottom: 1em;">
                    <v-container>
                        <v-row>
                            <v-col class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-8 pr-8">
                                <h2 class="text-center">In The Media</h2>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-lg-4 col-md-4 col-sm-12 col-xs-12" v-for="(item, index) in inthenews" v-bind:key="index">
                                <v-card class="mx-auto" max-width="420" height="420px" color="#ffffff">
                                    <a :href="item.url">
                                        <v-img :alt="item.home_image" :src="item.home_image" height="250px" width="auto">
                                        </v-img>
                                    </a>
                                    <div>
                                        <v-card-text>
                                            <div class="news-card-text">
                                                <h3 class="mt-0"><a :href="item.url" style="color:#0189E4;;text-decoration: underline;line-height: 1.25;">{{ item.title }}</a></h3>
                                                <div class="text-center">
                                                    <v-chip color="#1E1E1E" class="catchip text-center" :href="item.url" outlined>{{ item.date}}</v-chip>
                                                </div>
                                            </div>
                                        </v-card-text>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-8 pr-8">
                                <p class="text-center pt-4">
                                    <v-btn class="body-button blue" href="https://macguyvertech.com/media/" title="read more in the media">
                                        <v-icon color="#ffffff" small style="margin-right:.5em;">fas fa-photo-video</v-icon> View More In The Media
                                    </v-btn>
                                </p>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </section>
            <section class="homesection-demoapp" style="max-width: 900px;margin: 0 auto;">
                <v-hover v-slot="{ hover }">
                    <v-carousel continuous="true" :cycle="hover==true ? false : true" interval="8000" progress="true" reverse-transition="fade-transition" transition="fade-transition" :height="sliderHeight">
                        <div :style="'min-height: '+sliderHeight">
                            <v-carousel-item fade :height="sliderHeight">
                                <div class="video-elem">
                                    <div class="sub-section">
                                        <v-container>
                                            <v-row style="height:100%">
                                                <v-col class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-8 pr-8">
                                                    <h2 class="text-center pb-4"><span class="orange-text" style="color: #E75F03">H</span>acker <span class="orange-text" style="color: #E75F03">T</span>hreat <span class="orange-text" style="color: #E75F03">A</span>ssessment (<span class="orange-text" style="color: #E75F03">HTA</span>)</h2>
                                                    <div><iframe style="margin-bottom:20px;" class="youtube-video" src="https://www.youtube.com/embed/S2WMJp5dmPQ" title="Hacker Threat Assessment (HTA)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                                                    <p class="text-center pt-0">
                                                        Are you safe from HACKERS? Would you like to find out?</p>
                                                    <div class="formletstalk-center text-center pb-4 letstalk-button">
                                                        <v-btn class="body-button home-letstalk blue" v-bind="attrs" href="https://macguyvertech.com/our-services/hacker-threat-assessment-hta/" title="learn more">
                                                            <v-icon color="#ffffff" small style="margin-right:.5em;">mdi mdi-information</v-icon> Learn More
                                                        </v-btn>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </div>
                                </div>
                            </v-carousel-item>
                            <v-carousel-item fade :height="sliderHeight">
                                <div class="video-elem">
                                    <div class="sub-section">
                                        <v-container>
                                            <v-row>
                                                <v-col class="col-lg-12 col-md-12 col-sm-12 pl-8 pr-8">
                                                    <h2 class="text-center pb-4">Macguyver<span class="orange-text" style="color: #E75F03">Tech</span> Can <span class="orange-text" style="color: #E75F03">Boost</span> Your <span class="orange-text" style="color: #E75F03">Legacy</span> Apps.</h2>
                                                    <div><iframe style="margin-bottom:20px;" class="youtube-video" src="https://www.youtube.com/embed/7MscmTCDp_U" title="Legacy Apps" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                                                    <p class="text-center pt-0">
                                                        Transforming Legacy Systems into Efficient, Secure Assets for Future-Proof Business Operations.</p>
                                                    <div class="formletstalk-center text-center pb-4 letstalk-button">
                                                        <v-btn class="body-button home-letstalk blue" v-bind="attrs" href="https://macguyvertech.com/our-services/legacy-systems/" title="learn more">
                                                            <v-icon color="#ffffff" small style="margin-right:.5em;">mdi mdi-information</v-icon> Learn More
                                                        </v-btn>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </div>
                                </div>
                            </v-carousel-item>
                            <v-carousel-item fade :height="sliderHeight">
                                <div class="video-elem">
                                    <div class="sub-section">
                                        <v-container>
                                            <v-row>
                                                <v-col class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-8 pr-8">
                                                    <h2 class="text-center pb-4" style="line-height: 1em;">We Build <span class="orange-text" style="color: #E75F03">Useful, Usable Products</span> that Push the Limits of Technology and Innovation for many <a href="https://macguyvertech.com/industries/" style="color: #0189E4;">Industries</a></h2>
                                                    <v-img alt="MacguyverTech - Rhino Buildings" class="mx-auto" max-width="500" src="../images/home/demoapp-rhino.webp"></v-img>
                                                    <p class="text-center pt-4">
                                                        Your business has unique needs and we love to create beautiful applications that will stand the test of time.</p>
                                                    <p class="text-center">Let us help you build a glove fit system for your business.</p>
                                                    <div class="formletstalk-center text-center pb-4 letstalk-button">
                                                        <v-btn class="body-button home-letstalk blue" target="_blank" href="https://calendly.com/mactechconsultation/macguyvertech" title="Free Consultation">
                                                            <v-icon color="#ffffff" small style="margin-right:.5em;">fas fa-user-friends</v-icon> Free Consultation
                                                        </v-btn>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </div>
                                </div>
                            </v-carousel-item>
                        </div>
                    </v-carousel>
                </v-hover>
            </section>
            <FooterBar />
        </v-main>
        <v-dialog v-model="dialog" persistent max-width="800px" class="letstalk text-center">
            <v-card>
                <v-card-text class="pl-2 pr-2">
                    <v-row>
                        <v-col class="col-lg-12 col-md-12 col-sm-12 col-xs-12 footer-inner pa-0">
                            <v-form ref="form" lazy-validation>
                                <v-row class="ma-0 pa-0" style="min-height: 300px;">
                                    <v-col cols="12" sm="12" md="12" lg="12" class="form-wrapper ma-0 pa-0">
                                        <v-row class="pa-0 pt-4 pb-4">
                                            <v-col cols="12" class="pa-0">
                                                <div class="border-bottom pb-2 mb-6 pt-8">
                                                    <h2 class="">Let’s Talk!</h2>
                                                </div>
                                            </v-col>
                                            <div v-if="formCompleted">
                                                <v-col cols="12" class="pa-0">
                                                    <v-alert type="success">
                                                        <p class="white-text" style="width:100%;display: block;">Success! Thank you for contacting MacguyverTech. We will get back to you as soon as we can. Have a great day!</p>
                                                    </v-alert>
                                                </v-col>
                                            </div>
                                            <div v-else>
                                                <v-progress-circular v-if="showmoreActive" indeterminate color="primary"></v-progress-circular>
                                                <div v-else>
                                                    <v-row>
                                                        <v-col class="pa-2 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                            <v-subheader class="pl-0 pb-0" style="color:#000000; font-weight: bold;height: 20px !important;">Name</v-subheader>
                                                            <v-text-field style="" outlined label="" v-model="opts.name" :rules="nameRules"></v-text-field>
                                                        </v-col>
                                                        <v-col class="col-lg-6 col-md-12 col-sm-12 col-xs-12 pa-2">
                                                            <v-subheader class="pl-0 pb-0" style="color:#000000; font-weight: bold;height: 20px !important;">Email</v-subheader>
                                                            <v-text-field outlined label="" v-model="opts.email" :rules="emailRules"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" class="col-lg-12 col-md-12 col-sm-12 pa-2">
                                                            <v-subheader class="pl-0 pb-0" style="color:#000000; font-weight: bold;height: 20px !important;">Subject</v-subheader>
                                                            <v-select multiple :items="options" label="" outlined v-model="opts.subject"></v-select>
                                                        </v-col>
                                                        <v-col cols="12" class="col-lg-12 col-md-12 col-sm-12 pa-2">
                                                            <v-subheader class="pl-0 pb-0" style="color:#000000; font-weight: bold;height: 20px !important;">Message</v-subheader>
                                                            <v-text-field outlined label="" v-model="opts.message"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" class="col-lg-12 col-md-12 col-sm-12 pa-0 pt-2 pb-4">
                                                            <v-btn @click="submitForm()" color="primary" large class="mt-0 mr-4 blue">
                                                                <v-icon color="#ffffff" small style="margin-right:.5em;">mdi mdi-information</v-icon> Let's Talk!
                                                            </v-btn>
                                                            <v-btn ref="closeBtn" color="grey darken-1" text @click="dialog = false">
                                                                Close
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </div>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
</template>
<script>
//import lock from './plugins/scroll-lock'
import TopNavBar from './components/TopNavBar.vue'
//import FormLetstalk from './components/FormLetstalk.vue'
//import FormLetstalkCenter from './components/FormLetstalkCenter.vue'
import FooterBar from './components/FooterBar.vue'
import axios from 'axios'
export default {
    name: 'AppHome',
    components: { "TopNavBar": TopNavBar, "FooterBar": FooterBar },
    data: () => ({
        activePromoID: 0,
        //debug: false,
        width: 300,
        widthsm: 200,
        //post: [],
        posts: [],
        inthenews: [],
        whatwedo: [],
        ourservice: [],
        ourprocess: [],
        items: [],
        selected: [],
        siteurl: 'https://' + process.env.VUE_APP_HOST_NAME + '/lets-talk/',
        siteurl2: 'https://' + process.env.VUE_APP_HOST_NAME + '/blog/',
        selectedImage: '',
        dialog: false,
        dialogSubmited: false,
        opts: { name: '', subject: ['Custom Software Development'], email: '', message: '', hash: '' },
        options: ['Custom Software Development', 'Cyber Security Penetration Testing', 'Code Review And Remediation', 'Careers', 'Other'],
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Valid E-mail is required'
        ],
        nameRules: [
            v => !!v || 'Name is required',
            v => (v && v.length >= 3) || 'Name must be greater than 3 characters',
        ],
        showmoreActive: false,
        formCompleted: false,
        sliderHeight: '750px',
        boxHeight: 'auto',
        boxHeightText: 'height: 400px;position:relative;',
        fontSize: 'font-size: 15px;',
        learnBox: 'position:absolute;bottom: .75em; left: 0; right:0;',
    }),

    destroyed() {
        window.removeEventListener("resize", this.resizeWindow);
    },
    created() {

        let min = 1;
        let max = 5;

        this.selectedImage = 'https://' + process.env.VUE_APP_HOST_NAME + '/wp-content/themes/mactech/images/home/mtoffice' + Math.floor(Math.random() * (max - min + 1) + min) + '.webp';

        //this.activePromoID = this.getRandomBinary();
        this.resizeWindow;


    },
    mounted() {

        // get page data
        let id = document.querySelector('meta[name="pageid"]').content;
        axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/page/' + id)
            .then(res => {
                //console.log(response.data);
                this.inthenews = res.data[0].acf.in_the_media_images;
                //this.whatwedo = res.data[0].acf.what_we_do;
                //this.ourservice = res.data[0].acf.our_service;
                this.ourprocess = res.data[0].acf.our_process;

            }, error => {
                console.log(error);
            });

        axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/posts_home/1')
            .then(res => {
                //console.log('data', res.data);
                this.posts = res.data.map(x => x);

            }, error => {
                console.log(error);
            });

        window.addEventListener('touchmove', this.preventDefault, { passive: false });
        window.addEventListener("resize", this.resizeWindow);

        if (window.innerWidth >= '928') {
            this.sliderHeight = '750px'
        } else {

            this.sliderHeight = 'auto'
        }

        //console.log(window.innerWidth)
    },
    unmounted() {
        window.removeEventListener('touchmove', this.preventDefault, { passive: false });
    },
    methods: {

        dispatchWindowResize() {
            window.dispatchEvent(new Event('resize'))
        },

        resizeWindow() {
            //console.log(e)

            if (window.innerWidth >= '928') {
                this.sliderHeight = '750px'
            } else {

                this.sliderHeight = 'auto'
            }
            if (window.innerWidth >= '1500') {
                this.boxHeight = 'auto'
                this.boxHeightText = 'height: 400px;position:relative;'
                this.fontSize = 'font-size: 15px;'
                this.learnBox = 'position:absolute;bottom: .75em; left: 0; right:0;'
            } else if (window.innerWidth >= '1469') {
                this.boxHeight = 'auto'
                this.boxHeightText = 'height: 460px;position:relative;'
                this.fontSize = 'font-size: 15px;'
                this.learnBox = 'position:absolute;bottom: .75em; left: 0; right:0;'
            } else if (window.innerWidth >= '1264') {
                this.boxHeight = 'auto'
                this.boxHeightText = 'height: 460px;position:relative;'
                this.fontSize = 'font-size: 15px;'
                this.learnBox = 'position:absolute;bottom: .75em; left: 0; right:0;'
            } else {
                this.boxHeight = 'auto'
                this.boxHeightText = 'height:100%;position:relative;'
                this.fontSize = 'font-size: 14px;'
                this.learnBox = 'margin: 0 auto;'

            }

        },

        getRandomBinary() {
            return Math.round(Math.random());
        },

        resetForm() {

            this.formCompleted = false;
            this.showmoreActive = false;
            this.opts = { name: '', subject: ['Custom Software Development'], email: '', message: '', hash: '' }

        },

        hasher(str, seed = 0) {
            let h1 = 0xdeadbeef ^ seed,
                h2 = 0x41c6ce57 ^ seed;
            for (let i = 0, ch; i < str.length; i++) {
                ch = str.charCodeAt(i);
                h1 = Math.imul(h1 ^ ch, 2654435761);
                h2 = Math.imul(h2 ^ ch, 1597334677);
            }
            h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
            h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
            return 4294967296 * (2097151 & h2) + (h1 >>> 0);
        },

        submitForm() {

            if (this.$refs.form.validate()) {

                this.showmoreActive = true;

                this.opts.hash = this.hasher(Math.random())
                //console.log(this.opts);

                var t = fetch('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/send', {
                    method: 'post',
                    headers: {
                        'x-requested-with': 'XMLHttpRequest'
                    },
                    body: JSON.stringify(this.opts)
                }).then(function() {
                    //return response.json();
                    return true;
                })

                if (t) {
                    setTimeout(() => {

                        this.opts = { name: '', subject: ['Custom Software Development'], email: '', message: '', hash: '' }
                        this.showmoreActive = false;
                        this.formCompleted = true;
                        setTimeout(() => { this.dialog = false; }, 3000);
                        setTimeout(() => { this.resetForm(); }, 5000);
                    }, 1000);

                }

            }

        },

        goto(refName) {
            var element = this.$refs[refName];
            var top = element.offsetTop;
            window.scrollTo({
                top: top,
                behavior: 'smooth',
            })

        },

        preventDefault(e) {
            if (this.state.swiping || this.state.scale !== 1) {
                e.preventDefault();
                e.returnValue = false;
                return false;
            }
        },

    },
}
</script>
<style lang="scss">
.video-elem {
    height: 100%;
    width: 100%;
}

.v-carousel__controls {
    background: transparent !important;
    display: none;
}

* {
    box-sizing: border-box;
}

$duration: 60s;

@-webkit-keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

.ticker-wrap {

    position: absolute;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    height: 2rem;
    background-color: #fd9654;
    border-top: 6px solid #fd9654;

    padding-left: 100%;
    box-sizing: content-box;

    .ticker {

        display: inline-block;
        height: 2rem;
        line-height: 2rem;
        white-space: nowrap;
        padding-right: 100%;
        box-sizing: content-box;

        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-name: ticker;
        animation-name: ticker;
        -webkit-animation-duration: $duration;
        animation-duration: $duration;

        &__item {

            display: inline-block;

            padding: 0 2rem;
            font-size: 1rem;
            color: #363032;

        }

    }

}
</style>