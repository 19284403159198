<template>
    <v-app>
        <v-main touchless>
            <section class="pt-0">
                <v-container class="pb-0">
                    <v-row>
                        <v-col class="col-lg-12 pa-0 ma-0 pb-3 ">
                            <TopNavBar />
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <section class="pt-0">
                <div class="subpage-header-section border-top">
                    <h1 class="single-title">{{this.title}}</h1>
                </div>
                <v-container>
                    <v-row v-for="(item, index) in post" v-bind:key="index">
                        <v-col cols="12">
                            <div class="body-text pt-0">
                                <template>
                                    <div class="page-body">
                                        <v-row>
                                            <v-col cols="12">
                                                <div class="">
                                                    <h2 style="padding-bottom: 1em;">6 Steps to <span class="orange-text">PRODUCT SUCCESS</span></h2>
                                                    <div class="ourprocess-section">
                                                        <v-card color="#EFEFEF" dark width="100%" style="margin: 0;" class="text-center mb-2">
                                                            <div style=" margin: 0;">
                                                                <v-card-title class="ourprocess-vcard-title">
                                                                    <v-lazy>
                                                                        <img class="ourprocess-section-icon" src="../images/ourprocess/discovery.svg" height="35" />
                                                                    </v-lazy> 1.DISCOVERY
                                                                </v-card-title>
                                                            </div>
                                                        </v-card>
                                                        <h5>MacGuyverTech goes through an extensive and thorough research phase.</h5>
                                                        <p>In order to provide the best possible final product, our employees must eliminate all bias and preconceived thoughts about the situation. In order to do this MacGuyverTech goes through an extensive and thorough research phase. During this phase, the team is reviewing the architecture of the system, identifying the best possible solutions to solve specific problems. While the research is happening, the team is planning out their next moves as well as developing a timetable for the project as well as creating a budget or a quote for the client.</p>
                                                    </div>
                                                    <div class="ourprocess-section">
                                                        <v-card color="#EFEFEF" dark width="100%" style="margin: 0;" class="text-center mb-2">
                                                            <div style=" margin: 0;">
                                                                <v-card-title class="ourprocess-vcard-title">
                                                                    <v-lazy>
                                                                        <img class="ourprocess-section-icon" src="../images/ourprocess/kickoff.svg" />
                                                                    </v-lazy> 2.KICK OFF
                                                                </v-card-title>
                                                            </div>
                                                        </v-card>
                                                        <h5>Using all the information and data gathered our team creates a “work plan” to follow through the process.</h5>
                                                        <p>Using all the information and data gathered during the discovery phase, the team creates a “work plan” to follow through the process; thus, begins the physical development process. The team is already familiar with the scope of the project so they will understand the timetables and the criteria agreed upon. MacGuyverTech uses the “Jira” ticketing software which allows the team to easily record when they have accomplished the work assigned to them.</p>
                                                    </div>
                                                    <div class="ourprocess-section">
                                                        <v-card color="#EFEFEF" dark width="100%" style="margin: 0;" class="text-center mb-2">
                                                            <div style=" margin: 0;">
                                                                <v-card-title class="ourprocess-vcard-title">
                                                                    <v-lazy>
                                                                        <img class="ourprocess-section-icon" src="../images/ourprocess/development.svg" />
                                                                    </v-lazy> 3.DEVELOPMENT
                                                                </v-card-title>
                                                            </div>
                                                        </v-card>
                                                        <h5>During development MacGuyverTech focuses on finding the correct technology to fit your business.</h5>
                                                        <p>Part of the development phase in our process at MacGuyverTech is focused on finding the correct technology to fit your needs. Our developers are familiar with virtually everything and are specialized at finding the proper solution to the problem.</p>
                                                    </div>
                                                    <div class="ourprocess-section">
                                                        <v-card color="#EFEFEF" dark width="100%" style="margin: 0;" class="text-center mb-2">
                                                            <div style=" margin: 0;">
                                                                <v-card-title class="ourprocess-vcard-title">
                                                                    <v-lazy>
                                                                        <img class="ourprocess-section-icon" src="../images/ourprocess/qualityassurance.svg" />
                                                                    </v-lazy> 4.QUALITY ASSURANCE
                                                                </v-card-title>
                                                            </div>
                                                        </v-card>
                                                        <h5>Each part of a project is individually reviewed by a peer as well as proper quality testing.</h5>
                                                        <p>Quality assurance is one of the most essential parts for software success. To ensure that the code not just the software is properly created, each part of the project is individually reviewed by a peer as well as proper quality testing. We take pride in this step because we know the product we put forward is going to be associated with MacGuyverTech and we want to make sure the product we are being tasked with creates the best possible product.</p>
                                                    </div>
                                                    <div class="ourprocess-section">
                                                        <v-card color="#EFEFEF" dark width="100%" style="margin: 0;" class="text-center mb-2">
                                                            <div style=" margin: 0;">
                                                                <v-card-title class="ourprocess-vcard-title">
                                                                    <v-lazy>
                                                                        <img class="ourprocess-section-icon" src="../images/ourprocess/deployment.svg" />
                                                                    </v-lazy> 5.DEPLOYMENT
                                                                </v-card-title>
                                                            </div>
                                                        </v-card>
                                                        <h5>We work together our clients to ensure the deployment of the new system goes off without an issues.</h5>
                                                        <p>We ensure that users are familiar with the way the system is used to allow for easy day 1 deployment. The client’s team and MacGuyverTech’s team will work together to ensure the deployment of the new system goes off without an issue.</p>
                                                    </div>
                                                    <div class="ourprocess-section last">
                                                        <v-card color="#EFEFEF" dark width="100%" style="margin: 0;" class="text-center mb-2">
                                                            <div style=" margin: 0;">
                                                                <v-card-title class="ourprocess-vcard-title">
                                                                    <v-lazy>
                                                                        <img class="ourprocess-section-icon" src="../images/ourprocess/maintenance.svg" />
                                                                    </v-lazy> 6.MAINTENANCE
                                                                </v-card-title>
                                                            </div>
                                                        </v-card>
                                                        <h5>Let us perform all the necessary scans and tests to ensure your system stays up and running smoothly.</h5>
                                                        <p>After deployment we are capable of creating an agreement with the client to perform maintenance on the system we created. MacGuyverTech’s development team created the system, let us perform maintenance on it. In addition to regular maintenance, we are also offering cybersecurity auditing services. At MacGuyverTech we are capable of performing all the necessary scans and tests to perform a complete and accurate audit of your cybersecurity measures and detect any vulnerabilities. Unique to MacGuyverTech, not only are we capable of writing the report, we are also able to fix any of the issues or vulnerabilities discovered.</p>
                                                        <p>
                                                            <v-btn class="body-button mb-2" href="#" title="Lets Discuss How We Can Help" small>
                                                                <v-icon color="#ffffff" small style="margin-right:.5em;">fas fa-user-friends</v-icon> Lets Discuss How We Can Help
                                                            </v-btn>
                                                        </p>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </template>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <FooterBar />
        </v-main>
    </v-app>
</template>
<script>
import TopNavBar from './components/TopNavBar.vue'
import FooterBar from './components/FooterBar.vue'
import axios from 'axios'
export default {
    name: 'AppPageSingle',
    components: { "TopNavBar": TopNavBar, "FooterBar": FooterBar },
    data: () => ({
        //debug: false,
        post: [],
        title: 'Our Process',
    }),
    created() {},
    mounted() {

        let id = document.querySelector('meta[name="pageid"]').content;
        //console.log(this.post.length, id);

        if (this.post.length == 0) {

            axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/page/' + id)
            .then(res => {
                //console.log(response.data);
                this.post = res.data[0];

            }, error => {
                console.log(error);
            });

        }

    },
    methods: {},
}
</script>
<style>
/*
.page-body {

    background: #ffffff !important;
    min-height: 500px;
    text-align: left;
    padding-top: 1em;
}
*/
</style>