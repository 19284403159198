<template>
    <div>
        <vue-navigation-bar :options="navbarOptions">
        </vue-navigation-bar>
    </div>
</template>
<script>
export default {
    name: "TopNavBar",
    data() {
        return {
            appTitle: 'MacguyverTechNav',
            navbarOptions: {
                elementId: "main-navbar",
                isUsingVueRouter: false,
                mobileBreakpoint: 960,
                brandImagePath: "../../images/macguyvertech_logo_notext-sm.png",
                brandImage: require("../../images/macguyvertech_logo_notext-sm.png"),
                brandImageAltText: "MacguyverTech",
                collapseButtonOpenColor: "#661c23",
                collapseButtonCloseColor: "#000000",
                showBrandImageInMobilePopup: true,
                ariaLabelMainNav: "Main Navigation",
                tooltipAnimationType: "shift-away",
                tooltipPlacement: "bottom",
                menuOptionsLeft: [
/*
                    {
                        type: "link",
                        text: "Home",
                        activeColor: this.getHoverColor('https://' + process.env.VUE_APP_HOST_NAME + '/'),
                        activeState: this.getHoverState('https://' + process.env.VUE_APP_HOST_NAME + '/'),
                        path: 'https://' + process.env.VUE_APP_HOST_NAME + '/',
                        subMenuOptions: []
                    }
,
                    {
                        type: "link",
                        text: "Our Services",
                        activeColor: this.getHoverColor('https://' + process.env.VUE_APP_HOST_NAME + '/our-services/'),
                        activeState: this.getHoverState('https://' + process.env.VUE_APP_HOST_NAME + '/our-services/'),
                        path: 'https://' + process.env.VUE_APP_HOST_NAME + '/our-services/',
                        subMenuOptions: []
                    },
                    {
                        type: "link",
                        text: "Our Process",
                        activeColor: this.getHoverColor('https://' + process.env.VUE_APP_HOST_NAME + '/our-process/'),
                        activeState: this.getHoverState('https://' + process.env.VUE_APP_HOST_NAME + '/our-process/'),
                        path: 'https://' + process.env.VUE_APP_HOST_NAME + '/our-process/',
                        subMenuOptions: []
                    },
*/
                    {
                        type: "link",
                        text: "Our Work",
                        activeColor: this.getHoverColor('https://' + process.env.VUE_APP_HOST_NAME + '/our-work/'),
                        activeState: this.getHoverState('https://' + process.env.VUE_APP_HOST_NAME + '/our-work/'),
                        path: 'https://' + process.env.VUE_APP_HOST_NAME + '/our-work/',
                        
                        /*
                        subMenuOptions: [{
                                type: "link",
                                text: "Team",
                                subText: "Find out who we are. What makes us unique",
                                path: 'https://' + process.env.VUE_APP_HOST_NAME + '/about/team/',
                            },
                            {
                                type: "link",
                                text: "Privacy Policy",
                                subText: "We are committed to handling the information we collect securely and responsibly.",
                                path: 'https://' + process.env.VUE_APP_HOST_NAME + '/about/privacy-policy/',
                            },

                        ]
                        */
                        
                    },
                    {
                        type: "link",
                        text: "Our Services",
                        activeColor: this.getHoverColor('https://' + process.env.VUE_APP_HOST_NAME + '/our-services/'),
                        activeState: this.getHoverState('https://' + process.env.VUE_APP_HOST_NAME + '/our-services/'),
                        path: 'https://' + process.env.VUE_APP_HOST_NAME + '/our-services/',
                        subMenuOptions: []
                    },
                    {
                        type: "link",
                        text: "About Us",
                        activeColor: this.getHoverColor('https://' + process.env.VUE_APP_HOST_NAME + '/about-us/'),
                        activeState: this.getHoverState('https://' + process.env.VUE_APP_HOST_NAME + '/about-us/'),
                        path: 'https://' + process.env.VUE_APP_HOST_NAME + '/about-us/',
                        subMenuOptions: [],
                    },
                    {
                        type: "link",
                        text: "Partners",
                        activeColor: this.getHoverColor('https://' + process.env.VUE_APP_HOST_NAME + '/partners/'),
                        activeState: this.getHoverState('https://' + process.env.VUE_APP_HOST_NAME + '/partners/'),
                        path: 'https://' + process.env.VUE_APP_HOST_NAME + '/partners/',
                        subMenuOptions: [],
                    },
                    {
                        type: "link",
                        text: "Careers",
                        activeColor: this.getHoverColor('https://' + process.env.VUE_APP_HOST_NAME + '/careers/'),
                        activeState: this.getHoverState('https://' + process.env.VUE_APP_HOST_NAME + '/careers/'),
                        path: 'https://' + process.env.VUE_APP_HOST_NAME + '/careers/',
                        subMenuOptions: [],
                    },

                    {
                        type: "link",
                        text: "Media",
                        activeColor: this.getHoverColor('https://' + process.env.VUE_APP_HOST_NAME + '/media/'),
                        activeState: this.getHoverState('https://' + process.env.VUE_APP_HOST_NAME + '/media/'),
                        path: 'https://' + process.env.VUE_APP_HOST_NAME + '/media/',
                        subMenuOptions: [],
                       
                    },
                    {
                        type: "link",
                        text: "Blog",
                        activeColor: this.getHoverColor('https://' + process.env.VUE_APP_HOST_NAME + '/blog/'),
                        activeState: this.getHoverState('https://' + process.env.VUE_APP_HOST_NAME + '/blog/'),
                        path: 'https://' + process.env.VUE_APP_HOST_NAME + '/blog/',
                        subMenuOptions: [],
                       
                    },

                    {
                        type: "link",
                        text: "Let's Talk",
                        activeColor: 'https://calendly.com/mactechconsultation/macguyvertech',
                        activeState: 'https://calendly.com/mactechconsultation/macguyvertech',
                        path: 'https://calendly.com/mactechconsultation/macguyvertech',
                        subMenuOptions: [],
                        //lastNavItem: true
                    },
                    {
                        type: "link",
                        text: "(267) 225-7520",
                        activeColor: '',
                        activeState: '',
                        path: 'tel:+12672257520',
                        subMenuOptions: [],
                        lastNavItem: true
                    },

                ],
                menuOptionsRight: []
            },

        }
    },
    methods: {

        getHoverColor(path) {

            //console.log(path, window.location.href)

            if (path == window.location.href)
                return '#1A83FF';
            else
                return '#000000';
        },
        getHoverState(path) {

            //console.log(path, window.location.href)

            if (path == window.location.href)
                return 'underline';
            else
                return 'none';
        }

    },
};
</script>
<style>
</style>