<template>
    <v-app>
        <v-main touchless class="about-us">
            <section class="pt-0">
                <v-container class="pb-0">
                    <v-row>
                        <v-col class="col-lg-12 pa-0 ma-0 pb-3 ">
                            <TopNavBar />
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <section class="pt-0">
                <div class="subpage-header-section pb-4 border-top">
                    <h1 class="single-title">{{this.title}}</h1>
                </div>
            </section>
            <section class="pt-0 pb-4" style="background-color: #D9D9D9;min-height: 500px;">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <div class="body-text pt-0">
                                <template>
                                    <div class="page-body">
                                        <v-row>
                                            <v-col cols="12">
                                                <div class="pa-0 mt-0">
                                                    <h2 style="margin-bottom:1em;text-align: center;" class="orange-text">We Love To Help You To Stay Secure</h2>
                                                    <p style="text-align: center;">Thank you for intrests in our demos. You can download the full versions below.
                                                    </p>
                                                    <div v-if="formCompleted">
                                                        <v-alert type="success">
                                                            <p class="white-text">Success! We have sent you an email with the full PDF report. Let us know if you have any questions.</p>
                                                            <p>
                                                                <v-btn @click="resetForm()" color="white" large class="mt-0 black--text">
                                                                    <v-icon color="#ffffff" small style="margin-right:.5em;color:#000000;">mdi-arrow-back-circle</v-icon> Reset Form
                                                                </v-btn>
                                                            </p>
                                                        </v-alert>
                                                    </div>
                                                    <div v-else>
                                                        <v-progress-circular v-if="showmoreActive" indeterminate color="primary"></v-progress-circular>
                                                        <v-form v-else ref="form" lazy-validation>
                                                            <v-row class="ma-0 pa-0">
                                                                <v-col cols="12" sm="12" md="12" lg="12" class="form-wrapper ma-0 pa-2">
                                                                    <v-row>
                                                                        <v-col class="col-lg-6 col-md-12 col-sm-12 col-xs-12 pa-2">
                                                                            <v-subheader class="pl-0 pb-0">Name</v-subheader>
                                                                            <v-text-field solo label="" v-model="opts.name" :rules="nameRules"></v-text-field>
                                                                        </v-col>
                                                                        <v-col class="col-lg-6 col-md-12 col-sm-12 col-xs-12 pa-2">
                                                                            <v-subheader class="pl-0 pb-0">Email</v-subheader>
                                                                            <v-text-field solo label="" v-model="opts.email" :rules="emailRules"></v-text-field>
                                                                        </v-col>
                                                                        <v-col class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pa-2 pb-0 pt-0">
                                                                            <v-subheader class="pl-0 pb-0">Subject</v-subheader>
                                                                        </v-col>
                                                                        <v-col class="col-lg-6 col-md-12 col-sm-12 col-xs-12 pa-2">
                                                                            <v-checkbox class="ckbox-slim" dense v-model="opts.subject" label="MacguyverTech - Free Full Sample PenTest" value="MacguyverTech - Free Full Sample PenTest"></v-checkbox>
                                                                            <v-checkbox class="ckbox-slim" dense v-model="opts.subject" label="MacguyverTech - Free Full Sample CodeReview" value="MacguyverTech - Free Full Sample CodeReview"></v-checkbox>
                                                                            <v-checkbox class="ckbox-slim" dense v-model="opts.subject" label="MacguyverTech - Development Services" value="MacguyverTech - Development Services"></v-checkbox>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-col cols="12" class="pa-0 pt-8">
                                                                        <v-btn @click="submitForm()" color="primary" large class="mt-0">
                                                                            <v-icon color="#ffffff" small style="margin-right:.5em;">fas fa-user-friends</v-icon> Send Me more info!
                                                                        </v-btn>
                                                                    </v-col>
                                                                </v-col>
                                                            </v-row>
                                                        </v-form>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </template>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <template>
                <section class="footer" style="background-color: #ffffff;">
                    <div class="sub-section2" style="background-color: #ffffff;">
                        <v-container>
                            <v-row>
                                <v-col class="col-lg-12 col-md-12 col-sm-12 col-xs-12 footer-inner" style="background-color: #ffffff;">
                                    <v-row>
                                        <v-col cols="12" class="pr-0">
                                            <p class="white-text pt-3">
                                                <a href="https://www.linkedin.com/company/MacguyverTech/" class="white-text pr-3">
                                                    <v-icon class="white-text" style="color: #363032;">
                                                        fab fa-linkedin-in
                                                    </v-icon>
                                                </a>
                                                <a href="https://twitter.com/MacguyverTech01" class="white-text pr-3">
                                                    <v-icon class="white-text" style="color: #363032;">
                                                        fab fa-twitter
                                                    </v-icon>
                                                </a>
                                                <a href="https://www.tiktok.com/@MacguyverTech" class="white-text pr-3">
                                                    <v-icon class="white-text" style="color: #363032;">
                                                        fab fa-tiktok
                                                    </v-icon>
                                                </a>
                                                <a href="https://www.youtube.com/c/MacguyverTech" class="white-text pr-3">
                                                    <v-icon class="white-text" style="color: #363032;">
                                                        fab fa-youtube
                                                    </v-icon>
                                                </a>
                                                <a href="https://www.instagram.com/MacguyverTech/" class="white-text pr-3">
                                                    <v-icon class="white-text" style="color: #363032;">
                                                        fab fa-instagram
                                                    </v-icon>
                                                </a>
                                                <a href="https://MacguyverTech.medium.com/" class="white-text">
                                                    <v-icon class="white-text" style="color: #363032;">
                                                        fab fa-medium
                                                    </v-icon>
                                                </a>
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </section>
            </template>
        </v-main>
    </v-app>
</template>
<script>
import TopNavBar from './components/TopNavBar.vue'
import axios from 'axios'
export default {
    name: 'ContactUsDemos',
    components: { "TopNavBar": TopNavBar },
    data: () => ({
        //debug: false,
        post: [],
        title: "Demos",
        opts: { subject: [], name: '', email: '', hash: '' },
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Valid E-mail is required'
        ],
        nameRules: [
            v => !!v || 'Name is required',
            v => (v && v.length >= 3) || 'Name must be greater than 3 characters',
        ],
        subjectRules: [
            v => !!v || 'Must setect one option',
        ],
        showmoreActive: false,
        formCompleted: false
    }),
    created() {},
    mounted() {

        let urlFrom = new URL(location.href).searchParams.get('form');
        /*
        https://dev.MacguyverTech.com/demos/?form=pentest
        https://dev.MacguyverTech.com/demos/?form=codereview
        https://dev.MacguyverTech.com/demos/?form=development 

        MacguyverTech - Free Full Sample PenTest.pdf
        MacguyverTech - Free Full Sample CodeReview.pdf
        MacguyverTech - Full Development Services.pdf
        */

        if (urlFrom == 'pentest') {
            this.opts.subject.push('MacguyverTech - Free Full Sample PenTest');
        } else if (urlFrom == 'coderev') {
            this.opts.subject.push('MacguyverTech - Free Full Sample CodeReview');
        } else if (urlFrom == 'development') {
            this.opts.subject.push('MacguyverTech - Development Services');
        } else {
            this.opts.subject.push('MacguyverTech - Free Full Sample PenTest');
        }

        let id = document.querySelector('meta[name="pageid"]').content;
        //console.log(this.post.length, id);

        if (this.post.length == 0) {

            axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/page/' + id)
                .then(res => {
                    //console.log(response.data);
                    this.post = res.data[0];

                }, error => {
                    console.log(error);
                });

        }

    },
    methods: {

        hasher(str, seed = 0) {
            let h1 = 0xdeadbeef ^ seed,
                h2 = 0x41c6ce57 ^ seed;
            for (let i = 0, ch; i < str.length; i++) {
                ch = str.charCodeAt(i);
                h1 = Math.imul(h1 ^ ch, 2654435761);
                h2 = Math.imul(h2 ^ ch, 1597334677);
            }
            h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
            h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
            return 4294967296 * (2097151 & h2) + (h1 >>> 0);
        },

        resetForm() {

            this.formCompleted = false;
            this.showmoreActive = false;
            this.opts = { subject: [], name: '', email: '', hash: '' }
            this.opts.subject.push('MacguyverTech - Free Full Sample PenTest');

        },

        submitForm() {

            if (this.$refs.form.validate()) {

                this.showmoreActive = true;

                this.opts.hash = this.hasher(Math.random())
                //console.log(this.opts);

                let t = fetch('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/senddemo', {
                    method: 'post',
                    headers: {
                        'x-requested-with': 'XMLHttpRequest'
                    },
                    body: JSON.stringify(this.opts)
                }).then(function() {
                    //alert('Thank you for contacting MacguyverTech. We will get back to you as soon as we can.');
                    //window.location.reload(true);
                    return true;
                });

                if (t) {

                    setTimeout(function() { this.formCompleted = true; }.bind(this), 1000)
                }

            }

        },

    },
}
</script>
<style>
</style>