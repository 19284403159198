<template>
    <v-app>
        <v-main touchless class="about-us">
            <section class="pt-0">
                <v-container class="pb-0">
                    <v-row>
                        <v-col class="col-lg-12 pa-0 ma-0 pb-3">
                            <TopNavBar />
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <section class="pt-0">
                <div class="subpage-header-section pb-4 border-top">
                    <h1 class="single-title">{{this.title}}</h1>
                </div>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <div class="body-text pt-0">
                                <template>
                                    <div class="page-body">
                                        <v-row>
                                            <v-col cols="12">
                                                <div class="pa-0 mt-0" v-html="this.subsection1">
                                                </div>
                                                <div class="mb-4">
                                                    <v-btn class="body-button blue" href="https://macguyvertech.com/learnmore" target="_blank" title="Learn more about Us" small>
                                                        <v-icon color="#ffffff" small style="margin-right:.5em;">fa-regular fa-file-pdf</v-icon> Learn more about Us
                                                    </v-btn>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </template>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container class=" pa-0">
                    <v-row class="pb-0">
                        <v-col cols="12" class="pa-0">
                            <div class="body-text pt-0">
                                <template>
                                    <div class="page-body">
                                        <v-row>
                                            <v-col class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pa-0 pb-6 mt-4">
                                                <h2 style="text-align: center;"><span style="color: #0189E4;">Our Team</span></h2>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </template>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
            <div class="subpage-orange-section pb-4 pt-8" style="background-color: #e2eaf3;">
                <v-container>
                    <v-row v-for="(item, index) in ourteam" v-bind:key="index" class="pb-2">
                        <v-col cols="12">
                            <div class="body-text pt-0 pb-0">
                                <template>
                                    <div class="page-body">
                                        <v-row>
                                            <v-col class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pa-0 mt-0" v-if="item.youtube_video_id">
                                                <!--<youtube height="auto" player-width="100%" v-if="item.youtube_video_id" :video-id="item.youtube_video_id"></youtube>-->
                                                <iframe class="youtube-video" src="https://www.youtube.com/embed/DiQHyZxS6Vk?autoplay=0&amp;time=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fmacguyvertech.com&amp;widgetid=1" title="Meet Steven McKeon (Mac) the CEO of MacguyverTech" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </v-col>
                                            <v-col class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pa-0 mt-0 pr-4" v-if="!item.youtube_video_id">
                                                <h4>{{item.name}}</h4>
                                                <p><strong>{{item.title}}</strong></p>
                                                <div v-html="item.content"></div>
                                            </v-col>
                                            <v-col class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pa-0 mt-0 pr-4" v-if="item.youtube_video_id">
                                                <h4>{{item.name}}</h4>
                                                <p><strong>{{item.title}}</strong></p>
                                                <div v-html="item.content"></div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </template>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <div class="body-text pt-0">
                            <template>
                                <div class="page-body">
                                    <v-row>
                                        <v-col cols="12">
                                            <h2>Your Dream. <span class="orange-text mb-1">Our Mission</span></h2>
                                            <div class="pa-0 mt-0 pt-2 pb-6" v-html="this.subsection2">
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </template>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
            <FooterBar />
        </v-main>
    </v-app>
</template>
<script>
import TopNavBar from './components/TopNavBar.vue'
import FooterBar from './components/FooterBar.vue'
import axios from 'axios'
export default {
    name: 'AppPageSingle',
    components: { "TopNavBar": TopNavBar, "FooterBar": FooterBar },
    data: () => ({
        //debug: false,
        post: [],
        ourteam: [],
        title: 'About Us',
        subsection1: '',
        subsection2: '',

    }),
    created() {



    },
    mounted() {

        let id = document.querySelector('meta[name="pageid"]').content;
        //console.log(this.post.length, id);

        if (this.post.length == 0) {

            axios.get('https://' + process.env.VUE_APP_HOST_NAME + '/index.php/wp-json/api/v1/page/' + id)
                .then(res => {
                    console.log('data', res.data[0].acf);
                    this.post = res.data[0];
                    this.ourteam = res.data[0].acf.our_team;
                    this.subsection1 = res.data[0].acf.about_us_section_1
                    this.subsection2 = res.data[0].acf.about_us_section_2

                }, error => {
                    console.log(error);
                });

        }

    },
    methods: {},
}
</script>
<style>
/*
.page-body {

    background: #ffffff !important;
    min-height: 500px;
    text-align: left;
    padding-top: 1em;
}
*/
</style>